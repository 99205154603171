import { createSelector } from 'reselect';

const selectedAssetManagementApplications = state =>
  state.default.assetManagementApplications.assetManagementApplications;
const selectedAssetManagementApplication = state =>
  state.default.assetManagementApplications.assetManagementApplication;
const selectedAssetManagementApplicationsById = state =>
  state.default.assetManagementApplications.assetManagementApplicationsById;
const selectedAssetManagementApplicationsTotal = state =>
  state.default.assetManagementApplications.total;

export const selectAssetManagementApplications = createSelector(
  [selectedAssetManagementApplications],
  assetManagementApplications => assetManagementApplications,
);

export const selectAssetManagementApplication = createSelector(
  [selectedAssetManagementApplication],
  assetManagementApplication => assetManagementApplication,
);

export const selectAssetManagementApplicationsById = createSelector(
  [selectedAssetManagementApplicationsById],
  assetManagementApplicationsById => assetManagementApplicationsById,
);

export const selectAssetManagementTotal = createSelector(
  [selectedAssetManagementApplicationsTotal],
  total => total,
);
