import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { useUserinfo } from 'mss-component-library';

export default function HomeRedirect() {
  const [hasPermission] = useUserinfo();
  const { url } = useRouteMatch();

  let to = null;

  /*
   * TODO [RA210525] Redirect to first route the user has permission to.
   * see PBI #9746
   */

  if (hasPermission('view_center_dashboard')) {
    to = 'dashboard';
  } else if (
    hasPermission('view_center_alert') ||
    hasPermission('change_alert')
  ) {
    to = 'alerts';
  } else {
    to = 'home';
  }

  // strip trailing slashes
  const baseUrl = url.replace(/^(.+?)\/*?$/, '$1');

  return (
    <Switch>
      <Redirect to={`${baseUrl}/${to}`} />
    </Switch>
  );
}
