import { createAction, createSlice } from '@reduxjs/toolkit';
import { arrayToObject } from 'common/utils';

export const fetchSitesRequested = createAction('sites/fetchSitesRequested');
export const fetchSitesFailed = createAction('sites/fetchSitesFailed');

const sitesSlice = createSlice({
  name: 'sites',
  initialState: {
    site: {},
    sites: [],
    sitesById: {},
  },
  reducers: {
    fetchSitesCompleted(state, action) {
      return {
        ...state,
        sites: action.payload,
        sitesById: arrayToObject(action.payload),
      };
    },
    selectSite(state, action) {
      return { ...state, site: action.payload };
    },
    addSiteCompleted(state, action) {
      const newSites = [...state.sites, action.payload];
      return {
        ...state,
        sites: newSites,
        sitesById: arrayToObject(newSites),
      };
    },
    updateSiteCompleted(state, action) {
      const newSites = [...state.sites];
      const siteToUpdate = newSites.findIndex(x => x.id === action.payload.id);
      newSites[siteToUpdate] = action.payload;

      return { ...state, sites: newSites, sitesById: arrayToObject(newSites) };
    },
  },
});

export const {
  fetchSitesCompleted,
  selectSite,
  addSiteCompleted,
  updateSiteCompleted,
} = sitesSlice.actions;

export default sitesSlice.reducer;
