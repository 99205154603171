import Api from 'common/services/Api';
import { FieldAsset } from 'mss-component-library';
import _ from 'lodash';

export async function fetchFieldAsset({ assetId }): Promise<FieldAsset> {
  return Api.get(`/assets/${assetId}/`);
}

export async function fetchFieldAssets(parameters: {
  site: string;
}): Promise<FieldAsset[]> {
  parameters['type'] = 'field';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}
export async function fetchFieldAssetFilters(parameters: {
  site: string;
}): Promise<FieldAsset[]> {
  parameters['type'] = 'field';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/filters/`, { params: parameters });
}

export const fetchFieldAssetsHelper = async ({
  index,
  id,
  start,
  end,
  pagination,
  fetcher,
}) => {
  const response =
    start && end
      ? await fetcher({
          id,
          index,
          start,
          end,
          pagination,
        })
      : await fetcher({
          id,
          index,
          pagination,
        });
  return {
    items: _.get(response, 'hits.hits', []).map(o => o._source),
    total: _.get(response, 'hits.total.value', 0),
  };
};
