import { useSelector } from 'react-redux';
import { selectComputeAsset } from 'computeAssets/store/selectors';

function ComputeAssetBreadcrumb() {
  // TODO: Fetch compute asset based on assed id from state
  // const { computeAssetId } = match.params;

  const computeAsset = useSelector(selectComputeAsset);
  return computeAsset.name || null;
}

export default ComputeAssetBreadcrumb;
