import {
  fetchControlApplications,
  fetchControlApplication,
} from 'controlApps/services/controlAppsService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchControlApplicationsCompleted,
  fetchControlApplicationsFailed,
  fetchControlApplicationsRequested,
  fetchControlApplicationCompleted,
  fetchControlApplicationFailed,
  fetchControlApplicationRequested,
} from './slice';

function* fetchControlApplicationsEffectSaga(action) {
  try {
    let { data } = yield call(fetchControlApplications, action.payload);
    yield put(fetchControlApplicationsCompleted(data));
  } catch (e) {
    yield put(fetchControlApplicationsFailed());
  }
}

export function* fetchControlApplicationsWatcherSaga() {
  yield takeLatest(
    fetchControlApplicationsRequested.toString(),
    fetchControlApplicationsEffectSaga,
  );
}

function* fetchControlApplicationEffectSaga(action) {
  try {
    let { data } = yield call(fetchControlApplication, action.payload);
    yield put(fetchControlApplicationCompleted(data));
  } catch (e) {
    yield put(fetchControlApplicationFailed());
  }
}

export function* fetchControlApplicationWatcherSaga() {
  yield takeLatest(
    fetchControlApplicationRequested.toString(),
    fetchControlApplicationEffectSaga,
  );
}

export default function* assetManagementApplicationsRootSaga() {
  yield all([
    fetchControlApplicationsWatcherSaga(),
    fetchControlApplicationWatcherSaga(),
  ]);
}
