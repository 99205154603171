import { combineReducers } from 'redux';
import commonReducer from '../common/store';
import computeAssetsReducer from '../computeAssets/store';
import fieldAssetsReducer from '../fieldAssets/store';
import iiotAssetsReducer from '../iiotAssets/store';
import settingsReducer from '../settings/store';
import sitesReducer from '../sites/store';
import plcDcsReducer from '../plcDcsAssets/store';
import networkReducer from '../networkAssets/store';
import configurationReducer from '../configuration/store';
import securityAppsReducer from '../securityApps/store';
import assetManagementAppsReducer from '../assetManagementApps/store';
import analyzerManagementAppsReducer from '../analyzerManagementApps/store';
import controlAppsReducer from '../controlApps/store';
import envAssetsReducer from '../envAssets/store';
import dashboardsReducer from '../dashboards/store';
import reportsReducer from '../reports/store';

// https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)(Requested|Completed|Failed)/.exec(type);

  // not a *_REQUESTED / *_COMPLETED /  *_FAILED actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'Requested',
  };
};

export default combineReducers({
  loading: loadingReducer,
  computeAssets: computeAssetsReducer,
  fieldAssets: fieldAssetsReducer,
  iiotAssets: iiotAssetsReducer,
  sites: sitesReducer,
  common: commonReducer,
  settings: settingsReducer,
  plcDcsAssets: plcDcsReducer,
  networkAssets: networkReducer,
  configuration: configurationReducer,
  securityApps: securityAppsReducer,
  assetManagementApplications: assetManagementAppsReducer,
  analyzerManagementApplications: analyzerManagementAppsReducer,
  controlApplications: controlAppsReducer,
  envAssets: envAssetsReducer,
  dashboards: dashboardsReducer,
  reports: reportsReducer,
});
