import { Sliders, MapPin, UserCheck } from 'react-feather';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import React from 'react';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectMe } from 'settings/store/selectors';
import Divider from 'common/components/Divider';
import { useTranslation } from 'react-i18next';
import { PermissionGuard } from 'mss-component-library';

const CenterNavbar = () => {
  const user: any = useSelector(selectMe);
  const location = useLocation();
  const [t] = useTranslation('common');

  const isAuthURL =
    matchPath(location.pathname, {
      path: '/authorization',
    }) !== null;

  return (
    <>
      <NavItem>
        <NavLink className="nav-link" to="/sites/map" exact>
          <MapPin className="nav-icon" size={14} data-ta="Sitemap" />
          {t('Sitemap')}
        </NavLink>
      </NavItem>
      <>
        <Divider vertical />
        <PermissionGuard
          any
          codename={[
            'view_center_settings',
            'view_center_terms',
            'view_center_apr_alarm_library',
            'view_center_apr_alarm_match',
          ]}
        >
          <NavItem>
            <NavLink className="nav-link" to="/config">
              <Sliders
                className="nav-icon"
                size={14}
                data-ta="SitemapConfiguration"
              />
              {t('Configuration')}
            </NavLink>
          </NavItem>
        </PermissionGuard>
        <PermissionGuard
          any
          codename={['view_center_user', 'view_center_group']}
        >
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              className={`d-flex align-items-center ${
                isAuthURL ? 'active' : ''
              }`}
              data-ta="AuthorizationsMenu"
            >
              <UserCheck className="mr-1 mb-1" size={14} />
              {t('Authorizations')}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow">
              <PermissionGuard codename={['view_center_user']}>
                <DropdownItem
                  tag={NavLink}
                  to={`/authorization/users`}
                  data-ta="AuthorizationUsers"
                >
                  {t('Users')}
                </DropdownItem>
              </PermissionGuard>

              <PermissionGuard codename={['view_center_group']}>
                <DropdownItem
                  tag={NavLink}
                  to={`/authorization/groups`}
                  data-ta="AuthorizationGroups"
                >
                  {t('Groups')}
                </DropdownItem>
              </PermissionGuard>
            </DropdownMenu>
          </UncontrolledDropdown>
        </PermissionGuard>
        <PermissionGuard codename={['view_center_site_manager']}>
          <NavItem>
            <NavLink className="nav-link" to="/sites/manager">
              <FontAwesomeIcon
                icon={faLayerGroup}
                size={'sm'}
                style={{ marginRight: 4 }}
                data-ta="SiteManager"
              />
              {t('Site Manager')}
            </NavLink>
          </NavItem>
        </PermissionGuard>
      </>
    </>
  );
};

export default CenterNavbar;
