import { createSelector } from 'reselect';
import { ApiSite } from 'mss-component-library';

const selectSite = state => state.default.sites.site;
const selectSites = state => state.default.sites.sites;

export const selectedSites = createSelector(
  [selectSites],
  (sites: ApiSite[]) => sites,
);

export const selectedSite = createSelector(
  [selectSite],
  (site: ApiSite) => site,
);
