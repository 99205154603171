import Fetch from 'common/services/_fetch';

interface UpdateBody {
  name: string;
  id: number;
  permissions: number[];
}

export const update = async (body: UpdateBody) => {
  return Fetch.put(`/api/group/${body.id}/`, {
    body: JSON.stringify({ ...body }),
  });
};

export const updatePermissions = async (body: UpdateBody) => {
  return Fetch.patch(`/api/group/${body.id}/`, {
    body: JSON.stringify({ ...body }),
  });
};
