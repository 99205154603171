import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchCategories } from 'reports/services/ReportService';
import {
  fetchCategoriesCompleted,
  fetchCategoriesRequested,
  fetchCategoriesFailed,
} from './slice';

function* fetchCategoriesEffectSaga(action) {
  try {
    let { data } = yield call(fetchCategories, action.payload);
    yield put(fetchCategoriesCompleted(data));
  } catch (e) {
    yield put(fetchCategoriesFailed());
  }
}

export function* fetchCategoriesWatcherSaga() {
  yield takeLatest(fetchCategoriesRequested.toString(), fetchCategoriesEffectSaga);
}

export default function* categoriesRootSaga() {
  yield all([fetchCategoriesWatcherSaga()]);
}
