import { createSlice, createAction } from '@reduxjs/toolkit';

export const fetchAssetManagementApplicationsRequested = createAction<{
  query?: object;
}>('assetManagementApplications/fetchAssetManagementApplicationsRequested');

export const fetchAssetManagementApplicationsFailed = createAction(
  'assetManagementApplications/fetchAssetManagementApplicationsFailed',
);

export const fetchAssetManagementApplicationRequested = createAction<string>(
  'assetManagementApplications/fetchAssetManagementApplicationRequested',
);

export const fetchAssetManagementApplicationFailed = createAction(
  'assetManagementApplications/fetchAssetManagementApplicationFailed',
);

export const fetchAssetManagementApplicationsByIdRequested = createAction(
  'assetManagementApplications/fetchAssetManagementApplicationsByIdRequested');

export const fetchAssetManagementApplicationsByIdFailed = createAction(
  'assetManagementApplications/fetchAssetManagementApplicationsByIdFailed',
);

const assetManagementApplications = createSlice({
  name: 'assetManagementApplications',
  initialState: {
    assetManagementApplication: {},
    assetManagementApplications: [],
    assetManagementApplicationsById: {},
    total: 0,
  },
  reducers: {
    fetchAssetManagementApplicationsCompleted(state, action) {
      state.assetManagementApplications = action.payload;
    },
    fetchAssetManagementApplicationCompleted(state, action) {
      state.assetManagementApplication = action.payload;
    },
    fetchAssetManagementApplicationsByIdCompleted(state, action) {
      state.assetManagementApplicationsById = action.payload.reduce(
        (assetApp, current) => ({ ...assetApp, [current.id]: current }),
        {},
      );
    },
  },
});

export const {
  fetchAssetManagementApplicationsCompleted,
  fetchAssetManagementApplicationCompleted,
  fetchAssetManagementApplicationsByIdCompleted,
} = assetManagementApplications.actions;

export default assetManagementApplications.reducer;
