import Loading from 'common/components/Loading';
import { Switch } from 'mss-component-library';
import useFetchElasticQuery from 'common/hooks/useFetchElasticQuery';
import { objectDifference } from 'common/utils';
import ChangedEvent from 'computeAssets/components/ChangedEvent';
import { fetchComputeAssetNetworkInterfaceEvents_ab } from 'computeAssets/services/Agentbased/FetchComputeAssetNetworkInterfaceEvents';
import { fetchComputeAssetNetworkInterfaceEvents_al } from 'computeAssets/services/Agentless/FetchComputeAssetNetworkInterfaceEvents';
import get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import assetIndex from 'common/utils/queryIndexBuilder';
import { useSelector } from 'react-redux';
import { selectedSite } from 'sites/store/selectors';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

interface NetworkInterfaceEventsModalProps {
  id: number;
  networkInterface: string;
  friendlyName: string;
  closeModal(): void;
  isAgentBased: boolean;
}

interface InterfaceColumns {
  dhcp_enabled: string | boolean;
  friendly_name: string;
  address: string;
  physical_adapter: string;
  connection_status: string;
  description: string;
  interface: string;
  type: string;
  mac: string;
  enabled: string | boolean;
  manufacturer: string;
  mask: string;
  name?: string;
}

interface InterfaceColumnsDifference {
  dhcp_enabled?: string;
  friendly_name?: string;
  address?: string;
  physical_adapter?: string;
  connection_status?: string;
  description?: string;
  interface?: string;
  type?: string;
  mac?: string;
  enabled?: string;
  manufacturer?: string;
  mask?: string;
}

const ScheduledTaskEventModal: React.FC<NetworkInterfaceEventsModalProps> = ({
  id,
  networkInterface,
  friendlyName,
  closeModal,
  isAgentBased,
}: NetworkInterfaceEventsModalProps) => {
  const [t] = useTranslation('assetsCompute');
  const { id: siteId } = useSelector(selectedSite);
  const index = assetIndex({
    siteId,
    endpoint: 'catalogue',
    type: 'compute',
    subType: isAgentBased ? 'ab' : 'al',
  });

  const [showChanges, setShowChanges] = useState(false);
  const [loading, data] = useFetchElasticQuery(
    isAgentBased
      ? fetchComputeAssetNetworkInterfaceEvents_ab
      : fetchComputeAssetNetworkInterfaceEvents_al,
    isAgentBased
      ? 'fetchComputeAssetNetworkInterfaceEvents_ab'
      : 'fetchComputeAssetNetworkInterfaceEvents_al',
    {
      id,
      networkInterface: networkInterface,
      index,
    },
  );

  if (loading) return <Loading />;

  const hits = get(data, 'aggregations.top_uids_hits.hits.hits', []);

  const rows = hits.map((hit: any, index: number) => {
    const columns: InterfaceColumns = get(hit, '_source.json.columns');

    let difference: InterfaceColumnsDifference = {};

    if (index !== hits.length - 1) {
      difference = objectDifference(
        columns,
        get(hits[index + 1], '_source.json.columns'),
      );
    }

    //const calendarTime = get(hit, '_source.json.calendarTime');
    const calendarTime = hit._source['@timestamp'];

    return (
      <tr key={hit.key}>
        <td>{moment(calendarTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.interface}>
            {columns.interface}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.friendly_name}>
            {columns.friendly_name || columns.name}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.dhcp_enabled}>
            {columns.dhcp_enabled === true || columns.dhcp_enabled === '1'
              ? t('Yes')
              : t('No')}
          </ChangedEvent>
        </td>

        <td>
          <ChangedEvent changed={showChanges && !!difference.address}>
            {columns.address}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.mask}>
            {columns.mask}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.physical_adapter}>
            {columns.physical_adapter}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.connection_status}>
            {columns.connection_status}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.description}>
            {columns.description}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.type}>
            {columns.type || t('common:Unknown')}
            {(!isEmpty(columns.dhcp_enabled) ||
              typeof columns.dhcp_enabled === 'boolean') && (
              <p className="small text-muted">
                DHCP Enabled:{' '}
                {columns.dhcp_enabled === true || columns.dhcp_enabled === '1'
                  ? 'Yes'
                  : 'No'}
              </p>
            )}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.enabled}>
            {columns.enabled === true || columns.enabled === '1' ? 'Yes' : 'No'}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.mac}>
            {columns.mac}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.manufacturer}>
            {columns.manufacturer}
          </ChangedEvent>
        </td>
      </tr>
    );
  });

  return (
    <Modal isOpen toggle={closeModal} size="lg" scrollable={true}>
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        <div className="d-flex justify-content-between w-100">
          <div>{`${t('Network interface')}: ${friendlyName}`}</div>
          <div>
            <label className="mr-2 text-muted text-sm">
              {t('Show changes')}
            </label>
            <Switch
              size="small"
              onChange={() => setShowChanges(!showChanges)}
              isChecked={showChanges}
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Table responsive className="table-vcenter">
          <thead>
            <tr>
              <th>{t('Timestamp')}</th>
              <th>{t('Interface')}</th>
              <th>{t('Friendly name')}</th>
              <th>{t('DHCP enabled')}</th>
              <th>{t('Address')}</th>
              <th>{t('Mask')}</th>
              <th>{t('Physical adapter')}</th>
              <th>{t('Connection status')}</th>
              <th>{t('Description')}</th>
              <th>{t('Type')}</th>
              <th>{t('Enabled')}</th>
              <th>{t('Mac')}</th>
              <th>{t('Manufacturer')}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default ScheduledTaskEventModal;
