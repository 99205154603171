import React from 'react';

function Logo({ src = '' }) {
  return (
    <img
      className="ml-3 pl-3"
      style={{ maxHeight: 27, borderLeft: '1px solid #f7f7f7' }}
      src={src}
      alt={''}
    />
  );
}

export default Logo;
