import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { selectedSites } from 'sites/store/selectors';
import { useMemo } from 'react';

function SiteBreadcrumb() {
  const { siteId } = useParams<{ siteId: string }>();
  const sites = useSelector(selectedSites);

  const selectedSite = useMemo(() => {
    const site = sites.find(x => x.id === siteId);
    if (!site) return {};
    return site;
  }, [sites, siteId]);

  return get(selectedSite, 'name') || null;
}

export default SiteBreadcrumb;
