import React from 'react';

import Footer from 'common/components/Footer';

const Empty = props => {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <main id="page-content" className="my-4">
        {props.children}
      </main>
      <Footer />
    </div>
  );
};

export default Empty;
