import { useSelector } from 'react-redux';
import { selectNetworkAsset } from 'networkAssets/store/selectors';
import { NetworkAsset } from 'mss-component-library';

function NetworkAssetBreadcrumb() {
  const networkAsset: NetworkAsset = useSelector(selectNetworkAsset);

  return networkAsset?.name || null;
}

export default NetworkAssetBreadcrumb;
