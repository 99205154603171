import Route from 'common/components/Router/Route';
import React, { Suspense, useEffect } from 'react';
import {
  Switch,
  useRouteMatch,
  useParams,
  useHistory,
  Redirect,
} from 'react-router-dom';
import getDeepValueProperty from 'lodash/get';
import Layout from '../Layout/Layout';
import Loading from '../Loading';
import { useSelector, useDispatch } from 'react-redux';
import { selectedSite, selectedSites } from 'sites/store/selectors';
import { selectSite } from 'sites/store/slice';
import { UserProvider } from 'mss-component-library';
import RemoteAccess from 'remoteAccess/views';
import Alerts from 'alerts/views';
import Home from 'common/views/Home';
import HomeRedirect from './HomeRedirect';

const Dashboards = React.lazy(() => import('dashboards/views'));
const SecurityApplicationList = React.lazy(
  () => import('securityApps/views/List'),
);
const AssetManagementApplicationsList = React.lazy(
  () => import('assetManagementApps/views/List'),
);
const AssetManagementAppsDetail = React.lazy(
  () => import('assetManagementApps/views/Detail'),
);
const AnalyzerManagementApplicationsList = React.lazy(
  () => import('analyzerManagementApps/views/List'),
);
const AnalyzerManagementAppsDetail = React.lazy(
  () => import('analyzerManagementApps/views/Detail'),
);
const ControlApplicationsList = React.lazy(
  () => import('controlApps/views/List'),
);
const ControlAppsDetail = React.lazy(() => import('controlApps/views/Detail'));
const WsusApplicationDetail = React.lazy(
  () => import('securityApps/views/Detail/Wsus'),
);
const EpoApplicationDetail = React.lazy(
  () => import('securityApps/views/Detail/Epo'),
);
const VeeamApplicationDetail = React.lazy(
  () => import('securityApps/views/Detail/Veeam'),
);
const ClarotyApplicationDetail = React.lazy(
  () => import('securityApps/views/Detail/Claroty'),
);
const ClientsApplicationDetail = React.lazy(
  () => import('securityApps/views/Detail/Clients'),
);
const ThreatsApplicationDetail = React.lazy(
  () => import('securityApps/views/Detail/Threats'),
);
const ClientEventsApplicationDetail = React.lazy(
  () => import('securityApps/views/Detail/ClientEvents'),
);
const ServerEventsApplicationDetail = React.lazy(
  () => import('securityApps/views/Detail/ServerEvents'),
);
const ComputeAssetList = React.lazy(() => import('computeAssets/views/List'));
const ComputeAssetDetail = React.lazy(
  () => import('computeAssets/views/Detail'),
);
const FieldAssetList = React.lazy(() => import('fieldAssets/views/List'));
const FieldAssetDetail = React.lazy(() => import('fieldAssets/views/Detail'));
const PlcList = React.lazy(() => import('plcDcsAssets/views/List'));
const PlcAssetDetail = React.lazy(() => import('plcDcsAssets/views/Detail'));
const NetworkList = React.lazy(() => import('networkAssets/views/List'));
const NetworkAssetDetail = React.lazy(
  () => import('networkAssets/views/Detail'),
);
const EnvList = React.lazy(() => import('envAssets/views/List'));
const EnvAssetDetail = React.lazy(() => import('envAssets/views/Detail'));
const FileTransfer = React.lazy(() => import('fileTransfer/views'));
const ReportsList = React.lazy(() => import('reports/views/List'));
const ReportDetail = React.lazy(() => import('reports/views/Overview'));

const SiteRoutes = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { siteId } = useParams<{ siteId: string }>();
  const history = useHistory();
  const site = useSelector(selectedSite);
  const sites = useSelector(selectedSites);
  const { path } = match;

  useEffect(() => {
    if (siteId !== site.id) {
      const foundSites = sites.filter(x => x.id === siteId);
      if (foundSites.length > 0 && foundSites.length < 2) {
        dispatch(selectSite(foundSites[0]));
      } else {
        history.push('/sites/map');
      }
    }
  }, [siteId, site, sites, history, dispatch]);

  return (
    <UserProvider
      variant="center"
      opUrl={`${process.env.REACT_APP_API_URL}/me/object-permissions/${siteId}/`}
      path={`permissions.${siteId}`}
      url={`${process.env.REACT_APP_API_URL}/me/?site=${siteId}`}
    >
      <Layout layout="Site">
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path={`${path}/dashboard`} component={Dashboards} />
            <Route
              path={`${path}/applications/asset_management`}
              exact
              component={AssetManagementApplicationsList}
            />
            <Route
              path={`${path}/applications/asset_management/:asset_managementId`}
              component={AssetManagementAppsDetail}
            />
            <Route
              path={`${path}/applications/analyzer_management`}
              exact
              component={AnalyzerManagementApplicationsList}
            />
            <Route
              path={`${path}/applications/analyzer_management/:analyzer_managementId`}
              component={AnalyzerManagementAppsDetail}
            />
            <Route
              path={`${path}/applications/control`}
              exact
              component={ControlApplicationsList}
            />
            <Route
              path={`${path}/applications/control/:controlId`}
              component={ControlAppsDetail}
            />
            <Route
              path={`${path}/applications/security`}
              exact
              component={SecurityApplicationList}
            />
            <Route
              path={`${path}/applications/security/wsus/:securityAppId`}
              component={WsusApplicationDetail}
            />
            <Route
              path={`${path}/applications/security/veeam/:securityAppId`}
              component={VeeamApplicationDetail}
            />
            <Route
              path={`${path}/applications/security/claroty/:securityAppId`}
              component={ClarotyApplicationDetail}
            />
            <Route
              path={`${path}/applications/security/mcafee_epo/:securityAppId`}
              component={props => {
                const pageName = getDeepValueProperty(
                  props,
                  'location.pathname',
                  'overview',
                )
                  .split('/')
                  .pop();
                switch (pageName) {
                  case 'overview':
                    return <EpoApplicationDetail {...props} />;
                  case 'clients':
                    return <ClientsApplicationDetail {...props} />;
                  case 'threats':
                    return <ThreatsApplicationDetail {...props} />;
                  case 'clientEvents':
                    return <ClientEventsApplicationDetail {...props} />;
                  case 'serverEvents':
                    return <ServerEventsApplicationDetail {...props} />;
                  default:
                    return <EpoApplicationDetail {...props} />;
                }
              }}
            />
            <Route
              path={`${path}/assets/compute`}
              exact
              component={ComputeAssetList}
            />
            <Route
              path={`${path}/assets/compute/:computeAssetId`}
              component={ComputeAssetDetail}
            />
            <Route
              path={`${path}/assets/field`}
              exact
              component={FieldAssetList}
            />
            <Route
              path={`${path}/assets/field/:assetId`}
              component={FieldAssetDetail}
            />
            <Route path={`${path}/assets/plc-dcs`} exact component={PlcList} />
            <Route
              path={`${path}/assets/plc-dcs/:plcDcsAssetId`}
              component={PlcAssetDetail}
            />
            <Route
              path={`${path}/assets/network`}
              exact
              component={NetworkList}
            />
            <Route
              path={`${path}/assets/network/:networkAssetId`}
              component={NetworkAssetDetail}
            />
            <Route path={`${path}/alerts`} exact component={Alerts} />
            <Route path={`${path}/home`} exact component={Home} />
            <Route
              path={`${path}/remote-access`}
              exact
              component={RemoteAccess}
            />
            <Route
              path={`${path}/file-transfer`}
              exact
              component={FileTransfer}
            />
            <Route path={`${path}/assets/env`} exact component={EnvList} />
            <Route
              path={`${path}/assets/env/:envAssetId`}
              component={EnvAssetDetail}
            />
            <Route path={`${path}/reports`} exact component={ReportsList} />
            <Route path={`${path}/reports/:reportId`} component={ReportDetail} />
            <Route exact path={`${path}/`} component={HomeRedirect} />
            <Redirect to={`${path}/`} />
          </Switch>
        </Suspense>
      </Layout>
    </UserProvider>
  );
};

export default SiteRoutes;
