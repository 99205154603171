import {
  fetchNetworkAssets,
  fetchNetworkAsset,
} from 'networkAssets/services/NetworkAssetService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchNetworkAssetsCompleted,
  fetchNetworkAssetsFailed,
  fetchNetworkAssetsRequested,
  fetchNetworkAssetCompleted,
  fetchNetworkAssetFailed,
  fetchNetworkAssetRequested,
} from './slice';

function* fetchNetworkAssetsEffectSaga(action) {
  try {
    let { data } = yield call(fetchNetworkAssets, action.payload);
    yield put(fetchNetworkAssetsCompleted(data));
  } catch (e) {
    yield put(fetchNetworkAssetsFailed());
  }
}

export function* fetchNetworkAssetsWatcherSaga() {
  yield takeLatest(
    fetchNetworkAssetsRequested.toString(),
    fetchNetworkAssetsEffectSaga,
  );
}

function* fetchNetworkAssetEffectSaga(action) {
  try {
    let { data } = yield call(fetchNetworkAsset, action.payload);
    yield put(fetchNetworkAssetCompleted(data));
  } catch (e) {
    yield put(fetchNetworkAssetFailed());
  }
}

export function* fetchNetworkAssetWatcherSaga() {
  yield takeLatest(
    fetchNetworkAssetRequested.toString(),
    fetchNetworkAssetEffectSaga,
  );
}

export default function* networkAssetsRootSaga() {
  yield all([fetchNetworkAssetsWatcherSaga(), fetchNetworkAssetWatcherSaga()]);
}
