import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

// critical
// warning
// verbose
// error
// information

function EventLogLevel({ logLevel: origLog }) {
  const logLevel = origLog.toLowerCase();

  const classes = cn({
    'text-danger': logLevel === 'critical' || logLevel === 'error',
    'text-warning': logLevel === 'warning',
    'text-secondary':
      logLevel === 'verbose' ||
      logLevel === 'information' ||
      logLevel === 'security audit success',
  });
  return <FontAwesomeIcon className={classes} icon={faCircle} />;
}

export default EventLogLevel;
