import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    start: 'now-1h',
    end: 'now',
    refreshInterval: 3000,
    refreshCount: 0,
    isPaused: true,
    modals: {
      type: null,
      modalProps: {},
    },
  },
  reducers: {
    setDateRange(state, action) {
      state.start = action.payload.start;
      state.end = action.payload.end;
    },
    setStart(state, action) {
      state.start = action.payload.start;
    },
    setEnd(state, action) {
      state.end = action.payload.end;
    },
    setRefreshInterval(state, action) {
      state.refreshInterval = action.payload.refreshInterval;
    },
    setRefreshCount(state, action) {
      state.refreshCount = (state.refreshCount || 0) + 1;
    },

    setIsPaused(state, action) {
      state.isPaused = action.payload.isPaused;
    },
    showModal(state, action) {
      state.modals.modalProps = action.payload.modalProps;
      state.modals.type = action.payload.type;
    },
    hideModal(state) {
      state.modals.type = null;
      state.modals.modalProps = {};
    },
  },
});

export const {
  setStart,
  setEnd,
  setRefreshInterval,
  setRefreshCount,
  setIsPaused,
  setDateRange,
  showModal,
  hideModal,
} = commonSlice.actions;

export default commonSlice.reducer;
