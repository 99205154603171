export default function calculateAvgCpu(
  userValue: number,
  systemValue: number,
  numberOfCores: number,
  isPercentage = false,
): number {
  let width = (userValue * 100 + systemValue * 100) / numberOfCores;

  return isNaN(width) ? 0 : Math.round(width);
}
