import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import List from './List';

const Alerts = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={List} />
      <Route render={() => <h1>404 - Not found</h1>} />
    </Switch>
  );
};

export default Alerts;
