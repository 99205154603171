import { createSelector } from 'reselect';

const selectedEnvAssets = state => state.default.envAssets.envAssets;
const selectedEnvAsset = state => state.default.envAssets.envAsset;
const selectedEnvTotal = state => state.default.envAssets.total;

export const selectEnvAssets = createSelector(
  [selectedEnvAssets],
  envAssets => envAssets,
);

export const selectEnvAsset = createSelector(
  [selectedEnvAsset],
  envAssets => envAssets,
);

export const selectEnvTotal = createSelector(
  [selectedEnvTotal],
  total => total,
);
