import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const initI18n = (lng: string) => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng,
      fallbackLng: 'en',
      keySeparator: false,
      debug: !!process.env.REACT_APP_DEBUG_TRANSLATIONS,
      backend: {
        loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      },
    });
};

export default initI18n;
