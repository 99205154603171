import { TFunction } from 'i18next';

export const stateToString = (t: TFunction, value: string) => {
  switch (value) {
    case 'new':
      return t('New');
    case 'closed':
      return t('Closed');
    case 'acknowledged':
      return t('Acknowledged');
    case 'scheduled':
      return t('Scheduled');
    case 'awaiting_evidence':
      return t('Awaiting Evidence');
    case 'resolved':
      return t('Resolved');
    default:
      return value;
  }
};

export const priorityToString = (t: TFunction, value: string) => {
  switch (value) {
    case 'low':
      return t('automations:Low');
    case 'medium':
      return t('common:Medium');
    case 'high':
      return t('common:High');
    default:
      return value;
  }
};
