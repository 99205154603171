import {
  fetchSecurityApp,
  fetchSecurityApps,
} from 'securityApps/services/Wsus/SecurityAppsService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSecurityAppCompleted,
  fetchSecurityAppFailed,
  fetchSecurityAppRequested,
  fetchSecurityAppsCompleted,
  fetchSecurityAppsFailed,
  fetchSecurityAppsRequested,
} from './slice';

function* fetchSecurityAppsEffectSaga(action) {
  try {
    let { data } = yield call(fetchSecurityApps, action.payload);
    yield put(fetchSecurityAppsCompleted(data));
  } catch (e) {
    yield put(fetchSecurityAppsFailed());
  }
}

function* fetchSecurityAppsWatcherSaga() {
  yield takeLatest(
    fetchSecurityAppsRequested.toString(),
    fetchSecurityAppsEffectSaga,
  );
}

function* fetchSecurityAppEffectSaga(action) {
  try {
    let { data } = yield call(fetchSecurityApp, action.payload);
    yield put(fetchSecurityAppCompleted(data));
  } catch (e) {
    yield put(fetchSecurityAppFailed());
  }
}

function* fetchSecurityAppWatcherSaga() {
  yield takeLatest(
    fetchSecurityAppRequested.toString(),
    fetchSecurityAppEffectSaga,
  );
}

export default function* securityAppsRootSaga() {
  yield all([fetchSecurityAppsWatcherSaga(), fetchSecurityAppWatcherSaga()]);
}
