import { useSelector } from 'react-redux';
import { SubcategoryAlarmsEntityAPI } from 'reports/views/Phase';
import { selectedSubcategory } from 'reports/store/selectors';

function ReportsSubcategoryBreadcrumb() {
  const selectedPhaseSubcategory: SubcategoryAlarmsEntityAPI =
    useSelector(selectedSubcategory);

  return selectedPhaseSubcategory?.title || null;
}

export default ReportsSubcategoryBreadcrumb;
