import Api from 'common/services/Api';

const baseUrl = '/center';
export function fetchCenter() {
  return Api.get(baseUrl);
}

export function fetchCenterTerms() {
  return Api.get(`${baseUrl}/terms`);
}

export function updateCenter(body: any) {
  return Api.put(`${baseUrl}/`, body);
}

export function updateTerms(body: any) {
  return Api.put(`${baseUrl}/terms/`, body);
}
