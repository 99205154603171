import {
  fetchAssetManagementApplications,
  fetchAssetManagementApplication,
  fetchAssetManagementApplicationsById,
} from 'assetManagementApps/services/assetManagementAppsService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchAssetManagementApplicationsCompleted,
  fetchAssetManagementApplicationsFailed,
  fetchAssetManagementApplicationsRequested,
  fetchAssetManagementApplicationCompleted,
  fetchAssetManagementApplicationFailed,
  fetchAssetManagementApplicationRequested,
  fetchAssetManagementApplicationsByIdCompleted,
  fetchAssetManagementApplicationsByIdFailed,
  fetchAssetManagementApplicationsByIdRequested,
} from './slice';

function* fetchAssetManagementApplicationsEffectSaga(action) {
  try {
    let { data } = yield call(fetchAssetManagementApplications, action.payload);
    yield put(fetchAssetManagementApplicationsCompleted(data));
  } catch (e) {
    yield put(fetchAssetManagementApplicationsFailed());
  }
}

export function* fetchAssetManagementApplicationsWatcherSaga() {
  yield takeLatest(
    fetchAssetManagementApplicationsRequested.toString(),
    fetchAssetManagementApplicationsEffectSaga,
  );
}

function* fetchAssetManagementApplicationEffectSaga(action) {
  try {
    let { data } = yield call(fetchAssetManagementApplication, action.payload);
    yield put(fetchAssetManagementApplicationCompleted(data));
  } catch (e) {
    yield put(fetchAssetManagementApplicationFailed());
  }
}

export function* fetchAssetManagementApplicationWatcherSaga() {
  yield takeLatest(
    fetchAssetManagementApplicationRequested.toString(),
    fetchAssetManagementApplicationEffectSaga,
  );
}

function* fetchAssetManagementApplicationsByIdEffectSaga() {
  try {
    let { data } = yield call(fetchAssetManagementApplicationsById);
    yield put(fetchAssetManagementApplicationsByIdCompleted(data));
  } catch (e) {
    yield put(fetchAssetManagementApplicationsByIdFailed());
  }
}

export function* fetchAssetManagementApplicationsByIdWatcherSaga() {
  yield takeLatest(
    fetchAssetManagementApplicationsByIdRequested.toString(),
    fetchAssetManagementApplicationsByIdEffectSaga,
  );
}

export default function* assetManagementApplicationsRootSaga() {
  yield all([
    fetchAssetManagementApplicationsWatcherSaga(),
    fetchAssetManagementApplicationWatcherSaga(),
    fetchAssetManagementApplicationsByIdWatcherSaga()
  ]);
}
