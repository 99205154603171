import { createSlice, createAction } from '@reduxjs/toolkit';

export const fetchPlcDcsAssetsRequested = createAction<{ query?: object }>(
  'plcDcsAssets/fetchPlcDcsAssetsRequested',
);

export const fetchPlcDcsAssetsFailed = createAction(
  'plcDcsAssets/fetchPlcDcsAssetsFailed',
);

export const fetchPlcDcsAssetRequested = createAction<string>(
  'computeAssets/fetchPlcDcsAssetRequested',
);

export const fetchPlcDcsAssetFailed = createAction(
  'computeAssets/fetchPlcDcsAssetFailed',
);

const plcDcsAssets = createSlice({
  name: 'plcDcsAssets',
  initialState: {
    plcDcsAsset: {},
    plcDcsAssets: [],
    total: 0,
  },
  reducers: {
    fetchPlcDcsAssetsCompleted(state, action) {
      state.plcDcsAssets = action.payload;
    },
    fetchPlcDcsAssetCompleted(state, action) {
      state.plcDcsAsset = action.payload;
    },
  },
});

export const {
  fetchPlcDcsAssetsCompleted,
  fetchPlcDcsAssetCompleted,
} = plcDcsAssets.actions;

export default plcDcsAssets.reducer;
