import { createSelector } from 'reselect';
import { ApiDashboard } from 'dashboards/types';

const selectDashboard = state => state.default.dashboards.dashboard;
const selectDashboards = state => state.default.dashboards.dashboards;

export const selectedDashboards = createSelector(
  [selectDashboards],
  (dashboards: ApiDashboard[]) => dashboards,
);

export const selectedDashboard = createSelector(
  [selectDashboard],
  (dashboard: ApiDashboard) => dashboard,
);
