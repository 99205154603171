import get from 'lodash/get';

export default function arrayToObject<T>(
  arr: Array<T>,
  keyField = 'id',
): object {
  return Object.assign(
    {},
    ...arr.map(item => ({ [get(item, keyField)]: item })),
  );
}
