import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectIiotAsset } from 'iiotAssets/store/selectors';

function IIoTBreadcrumb() {
  const iiotAsset = useSelector(selectIiotAsset);

  return get(iiotAsset, '_source.device_id') || null;
}

export default IIoTBreadcrumb;
