import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchSites } from 'sites/services/SitesService';
import {
  fetchSitesCompleted,
  fetchSitesRequested,
  fetchSitesFailed,
} from './slice';

function* fetchSitesEffectSaga() {
  try {
    let { data } = yield call(fetchSites);
    yield put(fetchSitesCompleted(data));
  } catch (e) {
    yield put(fetchSitesFailed());
  }
}

export function* fetchSitesWatcherSaga() {
  yield takeLatest(fetchSitesRequested.toString(), fetchSitesEffectSaga);
}

export default function* sitesRootSaga() {
  yield all([fetchSitesWatcherSaga()]);
}
