import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
export async function fetchComputeAssetEventLogOccurence({
  id,
  eventId,
  index,
  timestamp = 'event.created',
}) {
  const query = {
    size: 0,
    sort: {
      [timestamp]: 'desc',
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': `${id}`,
            },
          },
          {
            match: {
              'winlog.event_id': `${eventId}`,
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}
