import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectedSite } from 'sites/store/selectors';

export default function AutoRouting() {
  const site = useSelector(selectedSite);
  if (site.id) return <Redirect to={`/site/${site.id}`} />;
  return <Redirect to="/sites/map" />;
}
