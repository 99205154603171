import { useSelector } from 'react-redux';
import { selectAnalyzerManagementApplication } from '../../../analyzerManagementApps/store/selectors';

function AnalyzerManagementAppsBreadcrumb() {
  const analyzerManagement: any = useSelector(
    selectAnalyzerManagementApplication,
  );
  return analyzerManagement?.name || null;
}

export default AnalyzerManagementAppsBreadcrumb;
