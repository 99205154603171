import { createSelector } from 'reselect';

const selectedPlcDcsAssets = state => state.default.plcDcsAssets.plcDcsAssets;
const selectedPlcDcsAsset = state => state.default.plcDcsAssets.plcDcsAsset;
const selectedPlcDcsTotal = state => state.default.plcDcsAssets.total;

export const selectPlcDcsAssets = createSelector(
  [selectedPlcDcsAssets],
  plcDcsAssets => plcDcsAssets,
);

export const selectPlcDcsAsset = createSelector(
  [selectedPlcDcsAsset],
  plcDcsAssets => plcDcsAssets,
);

export const selectPlcDcsTotal = createSelector(
  [selectedPlcDcsTotal],
  total => total,
);
