import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Drawer, Collapse } from 'antd';
import { useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { Components } from 'react-markdown';
import gfm from 'remark-gfm';
import { Typography } from 'antd';
import { List } from 'antd';
import Api from 'common/services/Api';

const { Title, Text } = Typography;

const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  width: 100%;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
  .markdown {
    width: 100%;
    img {
      width: 100%;
    }
  }
`;

const StyedNoInfoMesage = styled.p`
  padding: 10px 0 0 20px;
`;

interface QuestionDrawerProps {
  isDrawerVisible: boolean;
  setDrawerHasContent: (value: boolean) => void;
  handleClose: () => void;
}

const components: Components = {
  h1: ({ children }) => <Title level={3}>{children}</Title>,
  h2: ({ children }) => <Title level={4}>{children}</Title>,
  h3: ({ children }) => <Title level={4}>{children}</Title>,
  h4: ({ children }) => <Title level={4}>{children}</Title>,
  p: ({ children }) => <Typography.Paragraph>{children}</Typography.Paragraph>,
  ul: props => <List>{props.children}</List>,
  li: props => <List.Item>{props.children}</List.Item>,
  blockquote: props => <Text type="secondary">{props.children}</Text>,
};

export default function QuestionDrawer({
  isDrawerVisible,
  setDrawerHasContent,
  handleClose,
}: QuestionDrawerProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation(['common']);
  const temperedPath = useCallback(() => {
    return history.location.pathname.slice(1);
  }, [history.location.pathname]);

  const [data, setData] = useState<any>({});

  const fetchQuestions = useCallback(async () => {
    Api.get(`/wiki/?path=/${temperedPath()}&language=${i18n.language}`)
      .then(res => {
        setData(res.data);
        setDrawerHasContent(Object.keys(res.data).length > 0);
      })
      .catch(err => {
        console.error('Error getting wiki content', err);
      });
  }, [temperedPath, i18n.language, setDrawerHasContent]);

  useEffect(() => {
    fetchQuestions();
  }, [history.location.pathname, fetchQuestions]);

  return (
    <StyledDrawer
      title={t('Help Center')}
      placement="right"
      closable
      onClose={handleClose}
      visible={isDrawerVisible}
      width="40%"
    >
      {Object.keys(data).length !== 0 ? (
        data?.content.map((content: any, index: number) => (
          <Collapse key={`collapse-${index}`}>
            <StyledPanel header={content.title} key={index}>
              <ReactMarkdown
                className="markdown"
                components={components}
                remarkPlugins={[gfm]}
                children={content.markdown}
              />
            </StyledPanel>
          </Collapse>
        ))
      ) : (
        <StyedNoInfoMesage>
          {t('There is no information regarding this page')}
        </StyedNoInfoMesage>
      )}
    </StyledDrawer>
  );
}
