import React from 'react';

function Logo({ fill = '#FFFFFF', className = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="178.638"
      height="27.184"
      viewBox="0 0 47.265 7.192"
      className={className}
    >
      <path
        d="M43.724 7.06a8.216 8.216 0 0 1 3.408-3.52c0 .005-3.405-3.404-3.405-3.408-1.705 1.71-2.712 2.705-3.408 3.409-.001 0 .006.004 0 0 1.441.785 2.561 1.926 3.405 3.52"
        fill="#ffee01"
      />
      <path
        d="M3.011 6.094h-.772v-2.08L.132 1.028h.977l1.51 2.24L4.142 1.03h.967L3.01 3.997z"
        fill={fill}
      />
      <path
        d="M6.902.876c-.383 0-2.481.17-2.481 2.723 0 2.407 2.101 2.626 2.504 2.63.423.002 2.5-.245 2.5-2.694 0-2.322-1.869-2.66-2.523-2.66m.051 4.685c-.75 0-1.75-.437-1.75-2.015 0-1.44.953-2.013 1.702-2.013.686 0 1.733.448 1.733 2.08 0 1.602-1.155 1.948-1.685 1.948M10.576 6.092h-.768V1.025l.816.002v2.397l2.388-2.397h1.049l-2.107 2.114 2.273 2.948-.988.004-1.877-2.482-.786.743z"
        fill={fill}
      />
      <path
        d="M16.057.863c-.732.025-2.551.399-2.551 2.642 0 2.469 1.969 2.718 2.574 2.715.677-.004 2.45-.477 2.458-2.696.01-2.66-2.51-2.66-2.481-2.661m-.004 4.69c-.733 0-1.741-.391-1.741-2.01 0-1.546 1.012-2.013 1.723-2.013.894 0 1.691.661 1.691 1.98 0 1.703-1.081 2.044-1.673 2.044M21.393 3.426l2.196.002-.007 2.639h-.608l-.089-.489c-.475.464-.95.625-1.716.623-1.107-.002-2.318-.88-2.318-2.684 0-1.225.778-2.626 2.404-2.62.862 0 2.061.288 2.264 1.62l-.795.004c-.101-.336-.387-.992-1.402-.992-.832 0-1.653.506-1.653 2.048 0 1.459.913 1.999 1.613 1.999.684 0 1.33-.233 1.591-1.452h-1.476l-.004-.698M26.342 1.035l-1.04.002-1.62 5.052h.83l.431-1.402 1.98-.002.558 1.392.85-.008zm-1.204 2.998l.69-2.27.148.406.69 1.864zM27.397 1.028h.866c.68 2.75.814 3.232.964 3.77.12-.506.366-1.17 1.033-3.77h.783c.79 2.803.908 3.236 1.08 3.8.12-.47.285-1.029.96-3.8h.82l-1.356 5.051-.811.01c-.658-2.242-.738-2.514-1.071-3.695-.274.942-.328 1.185-1.031 3.69H28.8l-1.403-5.056M35.88 1.02h-.998l-1.79 5.062h.8l.46-1.354 2.014.002.49 1.349.837-.002zm-1.288 3.038l.779-2.243v-.01l.784 2.254z"
        fill={fill}
      />
    </svg>
  );
}

export default Logo;

export const YokoLogo = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      d="M23.49 47.993A56.808 56.808 0 0147 23.61C47 23.643 23.51.028 23.51 0 11.75 11.846 4.803 18.739 0 23.616c-.006 0 .042.027 0 0C9.942 29.054 17.669 36.958 23.49 48"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
