import Loading from 'common/components/Loading';
import { Switch } from 'mss-component-library';
import useFetchElasticQuery from 'common/hooks/useFetchElasticQuery';
import { objectDifference } from 'common/utils';
import ChangedEvent from 'computeAssets/components/ChangedEvent';
import { fetchComputeGroupEvents_ab } from 'computeAssets/services/Agentbased/FetchComputeGroupEvents';
import { fetchComputeGroupEvents_al } from 'computeAssets/services/Agentless/FetchComputeGroupEvents';
import get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import assetIndex from 'common/utils/queryIndexBuilder';
import { useSelector } from 'react-redux';
import { selectedSite } from 'sites/store/selectors';
import { useTranslation } from 'react-i18next';

interface GroupEventDifference {
  gid?: string;
  comment?: string;
  group_sid?: string;
  sid?: string;
  description?: string;
}

function GroupEventsModal({ id, groupName, isAgentBased, closeModal }) {
  const [t] = useTranslation('assetsCompute');
  const { id: siteId } = useSelector(selectedSite);
  const index = assetIndex({
    siteId,
    endpoint: 'catalogue',
    type: 'compute',
    subType: isAgentBased ? 'ab' : 'al',
  });

  const [showChanges, setShowChanges] = useState(false);
  const [loading, data] = useFetchElasticQuery(
    isAgentBased ? fetchComputeGroupEvents_ab : fetchComputeGroupEvents_al,
    isAgentBased ? 'fetchComputeGroupEvents_ab' : 'fetchComputeGroupEvents_al',
    {
      id,
      groupName,
      index,
    },
  );

  const dataSource = 'aggregations.top-uids.buckets[0].top_uids_hits.hits.hits';

  if (loading) return <Loading />;

  const hits = get(data, dataSource, []);

  const rows = hits.map((hit, index) => {
    const columns = get(hit, '_source.json.columns');
    const normalizedColumns = {
      ...columns,
      gid: columns.gid,
      comment: columns.comment || columns.description,
      group_sid: columns.group_sid || columns.sid,
    };

    let difference: GroupEventDifference = {};

    if (index !== hits.length - 1) {
      difference = objectDifference(
        columns,
        get(hits[index + 1], '_source.json.columns'),
      );
    }

    //get(hit, '_source.json.calendarTime') ||
    //get(hit, '_source.json.@timestamp');

    const calendarTime = hit._source['@timestamp'];

    return (
      <tr key={hit.key}>
        <td>{moment(calendarTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
        {isAgentBased && (
          <td>
            <ChangedEvent changed={showChanges && !!difference.gid}>
              {normalizedColumns.gid}
            </ChangedEvent>
          </td>
        )}
        <td>
          <ChangedEvent
            changed={
              showChanges && (!!difference.comment || !!difference.description)
            }
          >
            {normalizedColumns.comment}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent
            changed={
              showChanges && (!!difference.group_sid || !!difference.sid)
            }
          >
            {normalizedColumns.group_sid}
          </ChangedEvent>
        </td>
      </tr>
    );
  });

  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        <div className="d-flex justify-content-between w-100">
          <div> {`${t('Group')}: ${groupName}`}</div>
          <div>
            <label className="mr-2 text-muted text-sm">
              {t('Show changes')}
            </label>
            <Switch
              size="small"
              onChange={() => setShowChanges(!showChanges)}
              isChecked={showChanges}
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Table responsive className="table-vcenter">
          <thead>
            <tr>
              <th>{t('Timestamp')}</th>
              {isAgentBased && <th>Gid</th>}
              <th>{t('Comment')}</th>
              <th>{t('Group Sid')}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
}

export default GroupEventsModal;
