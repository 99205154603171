import { ApiSite } from 'mss-component-library';

const emptySite: ApiSite = {
  id: 'Automatically generated',
  name: '',
  timezone: '',
  focal_point: '',
  language: '',
  description: '',
  longitude: '',
  latitude: '',
  key: 'Automatically generated',
  customer_name: '',
  health_status: 'RED',
  site_connected_at: null,
  url: '',
  ip_address: '',
  logo: ''
};

export default emptySite;
