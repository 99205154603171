import { useSelector } from 'react-redux';
import { selectFieldAsset } from 'fieldAssets/store/selectors';

function FieldAssetBreadcrumb() {
  const fieldAsset = useSelector(selectFieldAsset);

  return fieldAsset?.name || null;
}

export default FieldAssetBreadcrumb;
