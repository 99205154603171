import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import { parseDateRange, calculateBuckets } from 'common/utils';

export async function fetchInterfacePacketMetrics({ id, index, start, end }) {
  /**
   *  Get interface packet data
   */
  const { startMoment, endMoment } = parseDateRange(start, end);
  const buckets = calculateBuckets(startMoment, endMoment);

  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_key': 'packets',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: `${startMoment.format()}`,
              lte: `${endMoment.format()}`,
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'snmp.int_id',
          size: 5000,
        },
        aggs: {
          data: {
            auto_date_histogram: {
              field: '@timestamp',
              buckets: buckets,
              minimum_interval: 'hour',
            },
            aggs: {
              avg_inc_packets: {
                avg: {
                  field: 'snmp.int_inc_packets',
                },
              },
              avg_outc_packets: {
                avg: {
                  field: 'snmp.int_outc_packets',
                },
              },
              interface_name: {
                top_hits: {
                  _source: ['snmp.int_name'],
                  size: 1,
                },
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
