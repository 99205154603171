import {
  fetchPlcDcsAssets,
  fetchPlcDcsAsset,
} from 'plcDcsAssets/services/PlcDcsAssetService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchPlcDcsAssetsCompleted,
  fetchPlcDcsAssetsFailed,
  fetchPlcDcsAssetsRequested,
  fetchPlcDcsAssetCompleted,
  fetchPlcDcsAssetFailed,
  fetchPlcDcsAssetRequested,
} from './slice';

function* fetchPlcDcsAssetsEffectSaga(action) {
  try {
    let { data } = yield call(fetchPlcDcsAssets, action.payload);
    yield put(fetchPlcDcsAssetsCompleted(data));
  } catch (e) {
    yield put(fetchPlcDcsAssetsFailed());
  }
}

export function* fetchPlcDcsAssetsWatcherSaga() {
  yield takeLatest(
    fetchPlcDcsAssetsRequested.toString(),
    fetchPlcDcsAssetsEffectSaga,
  );
}

function* fetchPlcDcsAssetEffectSaga(action) {
  try {
    let { data } = yield call(fetchPlcDcsAsset, action.payload);
    yield put(fetchPlcDcsAssetCompleted(data));
  } catch (e) {
    yield put(fetchPlcDcsAssetFailed());
  }
}

export function* fetchPlcDcsAssetWatcherSaga() {
  yield takeLatest(
    fetchPlcDcsAssetRequested.toString(),
    fetchPlcDcsAssetEffectSaga,
  );
}

export default function* plcDcsAssetsRootSaga() {
  yield all([fetchPlcDcsAssetsWatcherSaga(), fetchPlcDcsAssetWatcherSaga()]);
}
