import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
export async function fetchComputeAssetNetworkInterfaceEvents_al({
  id,
  networkInterface,
  index,
}) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': `${id}`,
            },
          },
          {
            match: {
              'json.name': 'pack_mss-win-inventory_interfaces',
            },
          },
          {
            match: {
              'json.columns.interface': `${networkInterface}`,
            },
          },
        ],
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 15,
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
