import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import { parseDateRange } from 'common/utils';
import { DateRangeParameters } from 'mss-component-library';

export function fetchIIotAssets({ start, end }: DateRangeParameters) {
  const { startMoment, endMoment } = parseDateRange(start, end);

  const query = {
    size: 1,
    sort: {
      '@timestamp': 'desc',
    },
    _source: {
      includes: [
        'device_id',
        'Sensor.BatteryLeft',
        'Sensor.PER',
        'Sensor.RSSI',
        'Sensor.SNR',
        'Sensor.Uptime',
        '@timestamp',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'Sensor.DataSet': 'Soundness information (HRI)',
            },
          },
          {
            exists: {
              field: 'Sensor.Uptime',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: `${startMoment.format()}`,
              lte: `${endMoment.format()}`,
            },
          },
        },
      },
    },
  };

  return fetchElasticQuery(`site0000001-yokogawa-iot-metrics*`, query);
}

export function fetchIIotAsset({ start, end }: DateRangeParameters) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    size: 1,
    sort: {
      '@timestamp': 'desc',
    },
    _source: {
      includes: [
        'device_id',
        'Sensor.BatteryLeft',
        'Sensor.PER',
        'Sensor.RSSI',
        'Sensor.SNR',
        'Sensor.Uptime',
        '@timestamp',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'Sensor.DataSet': 'Soundness information (HRI)',
            },
          },
          {
            exists: {
              field: 'Sensor.Uptime',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: `${startMoment.format()}`,
              lte: `${endMoment.format()}`,
            },
          },
        },
      },
    },
  };

  return fetchElasticQuery(`site0000001-yokogawa-iot-metrics*`, query);
}
