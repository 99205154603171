import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMeRequested } from 'settings/store/slice';
import { selectMe } from 'settings/store/selectors';
import { AccessDenied } from 'mss-component-library';

export default function UserGuard({ children }) {
  const user = useSelector(selectMe);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user.id) dispatch(fetchMeRequested());
  }, [dispatch, user.id]);
  if (user.is_active === false) return <AccessDenied variant="center" />;
  return user.id ? <>{children}</> : null;
}
