import { createSelector } from 'reselect';

const selectedIiotAssets = state => state.default.iiotAssets.iiotAssets;
const selectedIiotAsset = state => state.default.iiotAssets.iiotAsset;

export const selectIiotAssets = createSelector(
  [selectedIiotAssets],
  iiotAssets => iiotAssets,
);

export const selectIiotAsset = createSelector(
  [selectedIiotAsset],
  iiotAsset => iiotAsset,
);
