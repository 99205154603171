import { selectedModals } from 'common/store/selectors';
import { hideModal } from 'common/store/slice';

// Compute asset modals
import DiskEventsModal from 'computeAssets/components/Modals/DiskEventsModal';
import EventLogsModal from 'computeAssets/components/Modals/EventLogsModal';
import GroupEventsModal from 'computeAssets/components/Modals/GroupEventsModal';
import NetworkInterfaceEventsModal from 'computeAssets/components/Modals/NetworkInterfaceEventsModal';
import PatchEventsModal from 'computeAssets/components/Modals/PatchEventsModal';
import ProgramEventsModal from 'computeAssets/components/Modals/ProgramEventsModal';
import ScheduledTaskEventModal from 'computeAssets/components/Modals/ScheduledTaskEventModal';
import UserEventModal from 'computeAssets/components/Modals/UserEventModal';
import GeneralAndDetailsModal from 'assetManagementApps/components/Modals/GeneralAndDetailsModal';
import HistoricalMessagesModal from 'controlApps/components/Detail/HistoricalMessagesModal';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MODAL_COMPONENTS = {
  USER_EVENTS: UserEventModal,
  SCHEDULED_TASK_EVENTS: ScheduledTaskEventModal,
  GROUP_EVENTS: GroupEventsModal,
  EVENT_LOGS: EventLogsModal,
  PATCH_EVENTS: PatchEventsModal,
  PROGRAM_EVENTS: ProgramEventsModal,
  NETWORK_INTERFACE_EVENTS: NetworkInterfaceEventsModal,
  DISK_EVENTS: DiskEventsModal,
  GENERAL_AND_DETAILS_EVENT: GeneralAndDetailsModal,
  HISTORICAL_MESSAGES: HistoricalMessagesModal
};

const ModalRoot = () => {
  const { type, modalProps } = useSelector(selectedModals);
  const dispatch = useDispatch();

  if (!type) {
    // 'unlock' the UI
    document.body.classList.remove('modal-open');
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type];

  return (
    <SpecificModal {...modalProps} closeModal={() => dispatch(hideModal())} />
  );
};

export default ModalRoot;
