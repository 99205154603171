import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
export async function fetchComputeAssetEventLogDetail({
  id,
  recordId,
  index,
  timestamp = 'event.created',
}) {
  const query = {
    size: 1000,
    sort: {
      [timestamp]: 'desc',
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': `${id}`,
            },
          },
          {
            match: {
              'winlog.record_id': `${recordId}`,
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}
