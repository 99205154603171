import { fetchElasticQuery } from 'common/services/FetchElasticQuery';

export async function fetchClockinfoMetric({ id, index }) {
  /**
   *  get clock info metric
   */
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_key': 'clockinfo',
            },
          },
        ],
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'mss.id.keyword',
          size: 5000,
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['@timestamp', 'snmp'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
