import Loading from 'common/components/Loading';
import { Switch } from 'mss-component-library';
import useFetchElasticQuery from 'common/hooks/useFetchElasticQuery';
import { objectDifference } from 'common/utils';
import ChangedEvent from 'computeAssets/components/ChangedEvent';
import { fetchUserEvents_ab } from 'computeAssets/services/Agentbased/FetchUserEvents';
import { fetchUserEvents_al } from 'computeAssets/services/Agentless/FetchUserEvents';
import get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import assetIndex from 'common/utils/queryIndexBuilder';
import { useSelector } from 'react-redux';
import { selectedSite } from 'sites/store/selectors';
import { useTranslation } from 'react-i18next';

interface UserEventDifference {
  uid?: string;
  description?: string;
  type?: string;
  uuid?: string;
}

function UserEventModal({ closeModal, id, username, isAgentBased, ...props }) {
  const [t] = useTranslation('assetsCompute');
  const { id: siteId } = useSelector(selectedSite);
  const index = assetIndex({
    siteId,
    endpoint: 'catalogue',
    type: 'compute',
    subType: isAgentBased ? 'ab' : 'al',
  });

  const [showChanges, setShowChanges] = useState(false);
  const [loading, data] = useFetchElasticQuery(
    isAgentBased ? fetchUserEvents_ab : fetchUserEvents_al,
    isAgentBased ? 'fetchUserEvents_ab' : 'fetchUserEvents_al',
    {
      id,
      username,
      index,
    },
  );

  if (loading) return <Loading />;

  const hits = get(data, 'aggregations.top_uids_hits.hits.hits', []);

  const rows = hits.map((hit, index) => {
    const columns = get(hit, '_source.json.columns');

    let difference: UserEventDifference = {};

    if (index !== hits.length - 1) {
      difference = objectDifference(
        columns,
        get(hits[index + 1], '_source.json.columns'),
      );
    }

    // const calendarTime = get(hit, '_source.json.calendarTime');
    const calendarTime = hit._source['@timestamp']

    return (
      <tr key={hit.key}>
        <td>{moment(calendarTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.uid}>
            {columns.uid}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.description}>
            {columns.description}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.type}>
            {columns.type}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.uuid}>
            {columns.uuid}
          </ChangedEvent>
        </td>
      </tr>
    );
  });

  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader
        toggle={closeModal}
        className="w-100"
        cssModule={{ 'modal-title': 'w-100' }}
      >
        <div className="d-flex justify-content-between ">
          <div> {`${t('User')}: ${username}`}</div>
          <div>
            <label className="mr-2 text-muted text-sm">
              {t('Show changes')}
            </label>
            <Switch
              size="small"
              onChange={() => setShowChanges(!showChanges)}
              isChecked={showChanges}
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Table responsive className="table-vcenter">
          <thead>
            <tr>
              <th>{t('Timestamp')}</th>
              <th>{t('UID')}</th>
              <th>{t('Description')}</th>
              <th>{t('Type')}</th>
              <th>{t('UUID')}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
}

export default UserEventModal;
