import Api from 'common/services/Api';

export async function fetchSecurityApps(parameters: {
  site: string;
}): Promise<any[]> {
  parameters['type'] = 'application';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}

export async function fetchSecurityApp({ id, ...params }) {
  return Api.get(`/assets/${id}/`, { params });
}
