import {
  fetchAnalyzerManagementApplications,
  fetchAnalyzerManagementApplication,
  fetchAnalyzerManagementApplicationsById,
} from '../services/analyzerManagementAppsService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchAnalyzerManagementApplicationsCompleted,
  fetchAnalyzerManagementApplicationsFailed,
  fetchAnalyzerManagementApplicationsRequested,
  fetchAnalyzerManagementApplicationCompleted,
  fetchAnalyzerManagementApplicationFailed,
  fetchAnalyzerManagementApplicationRequested,
  fetchAnalyzerManagementApplicationsByIdCompleted,
  fetchAnalyzerManagementApplicationsByIdFailed,
  fetchAnalyzerManagementApplicationsByIdRequested,
} from './slice';

function* fetchAnalyzerManagementApplicationsEffectSaga(action) {
  try {
    let { data } = yield call(fetchAnalyzerManagementApplications, action.payload);
    yield put(fetchAnalyzerManagementApplicationsCompleted(data));
  } catch (e) {
    yield put(fetchAnalyzerManagementApplicationsFailed());
  }
}

export function* fetchAnalyzerManagementApplicationsWatcherSaga() {
  yield takeLatest(
    fetchAnalyzerManagementApplicationsRequested.toString(),
    fetchAnalyzerManagementApplicationsEffectSaga,
  );
}

function* fetchAnalyzerManagementApplicationEffectSaga(action) {
  try {
    let { data } = yield call(fetchAnalyzerManagementApplication, action.payload);
    yield put(fetchAnalyzerManagementApplicationCompleted(data));
  } catch (e) {
    yield put(fetchAnalyzerManagementApplicationFailed());
  }
}

export function* fetchAnalyzerManagementApplicationWatcherSaga() {
  yield takeLatest(
    fetchAnalyzerManagementApplicationRequested.toString(),
    fetchAnalyzerManagementApplicationEffectSaga,
  );
}

function* fetchAnalyzerManagementApplicationsByIdEffectSaga() {
  try {
    let { data } = yield call(fetchAnalyzerManagementApplicationsById);
    yield put(fetchAnalyzerManagementApplicationsByIdCompleted(data));
  } catch (e) {
    yield put(fetchAnalyzerManagementApplicationsByIdFailed());
  }
}

export function* fetchAnalyzerManagementApplicationsByIdWatcherSaga() {
  yield takeLatest(
    fetchAnalyzerManagementApplicationsByIdRequested.toString(),
    fetchAnalyzerManagementApplicationsByIdEffectSaga,
  );
}

export default function* analyzerManagementApplicationsRootSaga() {
  yield all([
    fetchAnalyzerManagementApplicationsWatcherSaga(),
    fetchAnalyzerManagementApplicationWatcherSaga(),
    fetchAnalyzerManagementApplicationsByIdWatcherSaga()
  ]);
}
