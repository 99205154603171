import RootModal from 'common/components/Modals/RootModal';
import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/scss/mss-style.scss';
import ErrorBoundary from 'common/components/HOC/ErrorBoundary';
import MainRoutes from 'common/components/Router/MainRoutes';
import configureStore from './store';
import UserGuard from 'common/components/Router/UserGuard';
import { ThemeProvider } from 'styled-components';
import {
  ContentTypeAPI,
  theme,
  useFetch,
} from 'mss-component-library';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const queryClient = new QueryClient();

const store = configureStore();

export interface AllPermissionAPI {
  id: number;
  name: string;
  codename: string;
  content_type: number;
}

interface IAppContext {
  allPermissions: AllPermissionAPI[];
  allPermissionsMap: Map<string, number>;
  contentTypes?: ContentTypeAPI[];
}

interface SentryData {
  dsn: string;
  environment: string;
  release: string;
}

export const AppContext = React.createContext<IAppContext>({
  allPermissions: [],
  allPermissionsMap: new Map<string, number>(),
  contentTypes: [],
});

const App: React.FC = () => {
  const [{ data: allPermissions }] = useFetch({
    url: '/api/permission/',
    defaultValue: []
  });
  const allPermissionsMap = useMemo(() => {
    const codes = new Map<string, number>();
    if (!allPermissions) return codes;
    allPermissions.forEach((x: any) => {
      if (codes.has(x.codename))
        process.env.NODE_ENV === 'development' &&
          console.info('Duplicate codename!', x);
      else codes.set(x.codename, x.id);
    });
    return codes;
  }, [allPermissions]);

  const [{ data: configData }] = useFetch({
    url: '/api/center/configuration/',
  });

  const sentryData: SentryData = configData?.sentry;

  if (sentryData) {
    Sentry.init({
      dsn: sentryData.dsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: sentryData.environment,
      release: sentryData.release
    });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppContext.Provider
            value={{
              allPermissions,
              allPermissionsMap,
            }}
          >
            <Router>
              <ErrorBoundary>
                <UserGuard>
                  <MainRoutes />
                </UserGuard>
              </ErrorBoundary>
            </Router>
            <RootModal />
          </AppContext.Provider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
