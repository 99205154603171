// import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import Api from 'common/services/Api';
import { EnvAsset } from 'mss-component-library';
import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
export { fetchTemperatureData } from './elastic/TemperatureMetrics';
export { fetchHumidityData } from './elastic/HumidityMetrics';
export { fetchIsolationData } from './elastic/IsolationMetrics';
export { fetchContactData } from './elastic/ContactMetrics';
export { fetchDustData } from './elastic/DustMetrics';
export { fetchCorrosionData } from './elastic/CorrosionMetrics';



export async function fetchEnvOverviewMetricsSensor({ assetId, index }) {
  const query = {
    size: 1,
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    _source: {
      includes: [
        '@timestamp',
        'Sensor*'
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_type': 'odu',
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchEnvAsset(
  assetId: string,
): Promise<EnvAsset[]> {
  return Api.get(`/assets/${assetId}/`);
}

export async function fetchEnvAssets(parameters: {
  site: string;
}): Promise<EnvAsset[]> {
  parameters['type'] = 'env';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}
