import { createSelector } from 'reselect';

const selectedAnalyzerManagementApplications = state => state.default.analyzerManagementApplications.analyzerManagementApplications;
const selectedAnalyzerManagementApplication = state =>
  state.default.analyzerManagementApplications.analyzerManagementApplication;
const selectedAnalyzerManagementApplicationsById = state =>
  state.default.analyzerManagementApplications.analyzerManagementApplicationsById;
const selectedAnalyzerManagementApplicationsTotal = state =>
  state.default.analyzerManagementApplications.total;

export const selectAnalyzerManagementApplications = createSelector(
  [selectedAnalyzerManagementApplications],
  analyzerManagementApplications => analyzerManagementApplications,
);

export const selectAnalyzerManagementApplication = createSelector(
  [selectedAnalyzerManagementApplication],
  analyzerManagementApplication => analyzerManagementApplication,
);

export const selectAnalyzerManagementApplicationsById = createSelector(
  [selectedAnalyzerManagementApplicationsById],
  analyzerManagementApplicationsById => analyzerManagementApplicationsById,
);

export const selectAnalyzerManagementTotal = createSelector(
  [selectedAnalyzerManagementApplicationsTotal],
  total => total,
);
