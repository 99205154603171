import axios from 'axios';
import { getToken } from './AuthService';
import { get } from 'lodash';
import { getStore } from 'store';
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

function authenticationErrorInterceptor(error) {
  return Promise.reject(error);
}

client.interceptors.request.use(
  function(config) {
    const token = getToken();

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const state = getStore()?.getState();
    const site_id = get(state, 'default.sites.site.id');

    if (site_id) {
      config.headers['X-Site-Id'] = site_id;
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  },
);

client.interceptors.response.use(
  response => response,
  authenticationErrorInterceptor,
);

export default client;
