import React, { useState } from 'react';
import { Collapse, Nav, Navbar as RSNavbar, NavbarToggler } from 'reactstrap';

function Navbar({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <RSNavbar expand="md">
      <NavbarToggler onClick={toggle} />
      <Collapse
        isOpen={isOpen}
        navbar
        className="h-100"
        style={{ marginBottom: '-1px' }}
      >
        <Nav className="container mr-auto" navbar>
          {children}
        </Nav>
      </Collapse>
    </RSNavbar>
  );
}

export default Navbar;
