import { createAction, createSlice } from '@reduxjs/toolkit';
import { arrayToObject } from 'common/utils';

export const fetchDashboardsRequested = createAction(
  'dashboards/fetchDashboardsRequested',
);
export const fetchDashboardsFailed = createAction(
  'dashboards/fetchDashboardsFailed',
);

const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState: {
    dashboard: {},
    dashboards: [],
    dashboardsById: {},
  },
  reducers: {
    fetchDashboardsCompleted(state, action) {
      return {
        ...state,
        dashboards: action.payload,
        dashboardsById: arrayToObject(action.payload),
      };
    },
    selectDashboard(state, action) {
      return { ...state, dashboard: action.payload };
    },
    addDashboardCompleted(state, action) {
      const newDashboards = [...state.dashboards, action.payload];
      return {
        ...state,
        dashboards: newDashboards,
        dashboardsById: arrayToObject(newDashboards),
      };
    },
    updateDashboardCompleted(state, action) {
      const newDashboards = [...state.dashboards];
      const dashboardToUpdate = newDashboards.findIndex(
        x => x.id === action.payload.id,
      );
      newDashboards[dashboardToUpdate] = action.payload;

      return {
        ...state,
        dashboards: newDashboards,
        dashboardsById: arrayToObject(newDashboards),
      };
    },
  },
});

export const {
  fetchDashboardsCompleted,
  selectDashboard,
  addDashboardCompleted,
  updateDashboardCompleted,
} = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
