import { permission } from './permission';
import { all, single } from './read';
import { logout } from './logout';

const index = {
  permission,
  all,
  single,
  logout,
};

export default index;
