import { useSelector } from 'react-redux';
import { selectEnvAsset } from 'envAssets/store/selectors';
import { EnvAsset } from 'mss-component-library';

function EnvAssetBreadcrumb() {
  const envAsset: EnvAsset = useSelector(selectEnvAsset);

  return envAsset?.name || null;
}

export default EnvAssetBreadcrumb;
