import Fetch from 'common/services/_fetch';

interface AddBody {
  user: string;
  group: number;
}

export const add = async (body: AddBody) => {
  return Fetch.post(`/api/user/group/`, {
    body: JSON.stringify(body),
  });
};
