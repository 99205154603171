import { useSelector } from 'react-redux';
import { selectPlcDcsAsset } from 'plcDcsAssets/store/selectors';
import { PlcDcsAsset } from 'mss-component-library';

function PlcDcsAssetBreadcrumb() {
  const plcAsset: PlcDcsAsset = useSelector(selectPlcDcsAsset);

  return plcAsset?.name || null;
}

export default PlcDcsAssetBreadcrumb;
