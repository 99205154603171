import React, { useState } from 'react';
import {
  Page,
  CompactTable,
  useFetch,
  useFilters,
  PageSidebar,
  usePageQuery,
  PermissionGuard,
  useUserinfo,
  RemoteRequest,
  openNotificationWithIcon,
} from 'mss-component-library';
import columns from './columns';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'common/components/Breadcrumbs';
import RemoteRequestViewModal from 'remoteAccess/components/RemoteRequestViewModal';
import DirectRemoteConnectModal from 'common/components/RemoteConnection/DirectRemoteConnectModal';
import { selectedSite } from 'sites/store/selectors';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';

const filterKeys = ['asset_name', 'status'];
const searchKeys = ['asset_name', 'status', 'status_text'];

const remoteAny = [
  'start_rdp_remotesession_center',
  'start_ssh_remotesession_center',
  'start_vnc_remotesession_center',
  'start_web_remotesession_center',
  'create_rdp_remoterequest_center',
  'create_ssh_remoterequest_center',
  'create_web_remoterequest_center',
];

const customOptionLabel = (t: TFunction) => (key: string, value: any) => {
  if (key === 'status') {
    switch (value) {
      case 'awaiting_approval':
        return t('common:Awaiting approval');
      case 'wait_for_start_date':
        return t('common:Future approved');
      case 'allow':
        return t('common:Approved');
      case 'denied':
        return t('common:Denied');
      case 'revoked':
        return t('common:Revoked');
      case 'expired':
        return t('common:Expired');
      default:
        return value;
    }
  }
  return value;
};

const customHeadingLabel = (t: TFunction) => (key: string) => {
  if (key === 'custom_username') return t('assetsCompute:User');
  if (key === 'asset') return t('Target Asset');
  if (key === 'status') return t('Status');
  if (key === 'asset_name') return t('Target Asset');
  return key;
};

const PAGE_SIZE = 10;

export default function List() {
  const [, , , hasAnyPermission] = useUserinfo();
  const [sessionRequests, setSessionsRequests] = useState<RemoteRequest[]>([]);
  const [connectModal, openConnectModal] = useState<any | null>(null);
  const [t] = useTranslation(['remoteAccess', 'common', 'assetsCompute']);
  const [modal, setModal] = useState<RemoteRequest | null>(null);
  const site = useSelector(selectedSite);

  const [rawRequests] = useFetch<RemoteRequest[]>({
    url: `${process.env.REACT_APP_API_URL}/remote-request/`,
    options: { headers: { 'X-Site-Id': site.id } },
    defaultValue: [],
    initialFetch: hasAnyPermission(remoteAny),
    onSucces: async ({ data }) => {
      try {
        setSessionsRequests(
          data.map(x => ({
            ...x,
            asset_name: x.asset_object?.name,
            status_text: x.approval_status === 'allow' ? 'approved' : null,
          })),
        );
      } catch {
        openNotificationWithIcon(
          notification.error,
          t('Failed fetch assets'),
          t(
            'Due to technical issues assets could not be retrieved. Therefore you will only see the id of the assets. Please try again later.',
          ),
        );
      }
    },
  });

  const isFetching = rawRequests.loading;
  const dataSource = sessionRequests;

  const [availableFilters, toggleFilter, filteredData, search] = useFilters(
    dataSource,
    filterKeys,
    searchKeys,
    customOptionLabel(t),
    customHeadingLabel(t),
  );

  const [page, goToPage] = usePageQuery(filteredData.length / PAGE_SIZE);

  return (
    <PermissionGuard any codename={remoteAny}>
      <Page
        breadCrumbs={<Breadcrumbs />}
        padding={false}
        main={
          <>
            <CompactTable
              bordered={false}
              dataSource={filteredData}
              loading={isFetching}
              columns={columns(t, openConnectModal)}
              rowKey={'id'}
              onRow={(record: any) => ({
                onClick: () => setModal(record),
                style: { cursor: 'default' },
              })}
              pagination={{
                current: page,
                showQuickJumper: true,
                showSizeChanger: true,
                total: filteredData.length,
                showTotal: (total: number, range: any[]) =>
                  t('common:(RANGE1)-(RANGE2) of (TOTAL) items', {
                    range1: range[0],
                    range2: range[1],
                    TOTAL: total,
                  }),
              }}
              onChange={(pagination: any, filters: any, sorter: any) => {
                if (page !== pagination.current) goToPage(pagination.current);
              }}
            />
            <RemoteRequestViewModal
              request={modal}
              closeModal={() => {
                setModal(null);
              }}
              visible={!!modal}
            />
          </>
        }
        sidebar={
          <PageSidebar
            availableFilters={availableFilters}
            toggleFilter={toggleFilter}
            search={search}
          />
        }
      />
      <DirectRemoteConnectModal
        closeModal={() => openConnectModal(null)}
        remoteRequest={connectModal}
        visible={!!connectModal}
      />
    </PermissionGuard>
  );
}
