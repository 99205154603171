import moment from 'moment';

export default function calculateBuckets(startMoment, endMoment, max = 300) {
  /**
   * Calculate the number of buckets to query using the following business
   * rule:
   *
   * buckets = (hours in range) * 4 with max of <max (default 300)>
   */

  const duration = moment.duration(endMoment.diff(startMoment));
  const hours = duration.asHours();
  const buckets = (hours || 1) * 4;
  return buckets > max ? max : buckets;
}
