import Api from 'common/services/Api';
interface RequestRemoteBody {
  asset: string;
  protocol: string;
  ticket_id: string;
  request_message: string;
  dateRange: any[];
}
export default {
  session: {
    start: async (params: any) => {
      return Api.post(`/remote-session/start/`, params);
    },
  },
  asset: {
    remote: {
      request: async (body: RequestRemoteBody) => {
        const [
          approved_start_date = null,
          approved_end_date = null,
        ] = body.dateRange;

        const sanitizedValues = {
          ...body,
          approved_start_date: approved_start_date,
          approved_end_date: approved_end_date,
        };

        delete sanitizedValues.dateRange;

        return Api.post(`/remote-request/`, sanitizedValues);
      },
    },
  },
};
