import { add } from './add';
import { all } from './read';
import { update, updatePermissions } from './update';
import { remove } from './delete';
import user from './User';

const index = {
  add,
  update,
  updatePermissions,
  remove,
  user,
  all,
};

export default index;
