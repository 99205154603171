import styled from 'styled-components';

interface ChangedEventProps {
  changed: boolean;
}

const ChangedEvent = styled.span<ChangedEventProps>`
  background-color: ${({ changed }) =>
    changed ? 'var(--warning)' : 'transparent'};
`;

export default ChangedEvent;
