import Loading from 'common/components/Loading';
import { Switch } from 'mss-component-library';
import useFetchElasticQuery from 'common/hooks/useFetchElasticQuery';
import { humanFileSize, objectDifference } from 'common/utils';
import { fetchDiskEvents_ab } from 'computeAssets/services/Agentbased/FetchDiskEvents';
import { fetchDiskEvents_al } from 'computeAssets/services/Agentless/FetchDiskEvents';
import get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import ChangedEvent from 'computeAssets/components/ChangedEvent';
import assetIndex from 'common/utils/queryIndexBuilder';
import { useTranslation } from 'react-i18next';

interface DiskEventDifference {
  device_id?: string;
  boot_partition?: string;
  file_system?: string;
  size?: number;
  free_space?: number;
}

function DiskEventsModal({ id, deviceId, isAgentBased, closeModal, siteId }) {
  const [t] = useTranslation('assetsCompute');
  const index = assetIndex({
    siteId,
    endpoint: 'catalogue',
    type: 'compute',
    subType: isAgentBased ? 'ab' : 'al',
  });

  const [showChanges, setShowChanges] = useState(false);
  const [loading, data] = useFetchElasticQuery(
    isAgentBased ? fetchDiskEvents_ab : fetchDiskEvents_al,
    isAgentBased ? 'fetchDiskEvents_ab' : 'fetchDiskEvents_al',
    {
      id,
      deviceId,
      index,
    },
  );

  if (loading) return <Loading />;

  const hits = get(data, 'aggregations.top_uids_hits.hits.hits', []);

  const rows = hits.map((hit, index) => {
    const columns = get(hit, '_source.json.columns');

    let difference: DiskEventDifference = {};

    if (index !== hits.length - 1) {
      difference = objectDifference(
        columns,
        get(hits[index + 1], '_source.json.columns'),
      );
    }

    const calendarTime = hit._source['@timestamp'];

    const usedSpace = columns.size - columns.free_space;
    const size = columns.size !== '' ? columns.size : 0;

    return (
      <tr key={`hit_${index}`}>
        <td>{moment(calendarTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.device_id}>
            {columns.device_id}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.boot_partition}>
            {columns.boot_partition}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.file_system}>
            {columns.file_system}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent
            changed={
              showChanges && (!!difference.size || !!difference.free_space)
            }
          >
            {humanFileSize(usedSpace)} / {humanFileSize(size)}
          </ChangedEvent>
        </td>
      </tr>
    );
  });

  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        <div className="d-flex justify-content-between w-100">
          <div>{`${t('Disk')}: ${deviceId}`}</div>
          <div>
            <label className="mr-2 text-muted text-sm">
              {t('Show changes')}
            </label>
            <Switch
              size="small"
              onChange={() => setShowChanges(!showChanges)}
              isChecked={showChanges}
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Table responsive className="table-vcenter">
          <thead>
            <tr>
              <th>{t('Timestamp')}</th>
              <th>{t('Device id')}</th>
              <th>{t('Boot partition')}</th>
              <th>{t('File system')}</th>
              <th>{t('Space')}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
}

export default DiskEventsModal;
