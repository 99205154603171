import React from 'react';
import { Table } from 'reactstrap';
import flatten from 'flat';

function ObjectTable({ data }) {
  const rows = [];
  const flattenData = flatten(data);
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(flattenData)) {
    rows.push(
      <tr key={key}>
        <td style={{ width: '300px' }}>{key}</td>
        <td>{value}</td>
      </tr>,
    );
  }
  return (
    <Table>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export default ObjectTable;
