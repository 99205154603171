import { createSelector } from 'reselect';

export const selectedSecurityApps = state => {
  return state.default.securityApps.securityApps;
};

export const selectedSecurityApp = state =>
  state.default.securityApps.securityApp;

export const selectSecurityApps = createSelector(
  [selectedSecurityApps],
  (securityApps: any[]) => securityApps,
);

export const selectSecurityApp = createSelector(
  [selectedSecurityApp],
  (securityApp: any) => securityApp,
);
