import { SearchResponse } from 'elasticsearch';
import { QueryObserverOptions, useQuery } from 'react-query';

function useFetchElasticQuery<TParameters extends object, TResult>(
  fetch,
  queryKey: string,
  parameters?: TParameters,
  options?: QueryObserverOptions<SearchResponse<TResult>>,
): [boolean, SearchResponse<TResult>, any] {
  const { data, isLoading, error } = useQuery<
    SearchResponse<TResult>,
    TParameters
  >([queryKey, parameters], () => fetch(parameters), {
    refetchInterval: false,
    ...options,
  });

  return [isLoading, data, error];
}

export default useFetchElasticQuery;
