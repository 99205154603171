import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  me,
  navigation,
  acceptTerms,
  languages,
  timezones,
} from 'settings/services/SettingsService';
import {
  fetchMeCompleted,
  fetchMeFailed,
  fetchMeRequested,
  fetchNavCompleted,
  fetchNavFailed,
  fetchNavRequested,
  acceptTermsFailed,
  acceptTermsRequested,
  acceptTermsCompleted,
  fetchTimezonesCompleted,
  fetchTimezonesFailed,
  fetchTimezonesRequested,
  fetchLanguagesFailed,
  fetchLanguagesRequested,
  fetchLanguagesCompleted,
} from './slice';

function* fetchMeEffectSaga() {
  try {
    let result = yield call(me);
    if (result.status !== 200) window.location.href = `/`;
    yield put(fetchMeCompleted(result.data));
  } catch (e) {
    if (e.response.status === 403) {
      // Backend returns 403 if user is not allowed to login
      yield put(fetchMeCompleted({ is_active: false }));
    }
    yield put(fetchMeFailed());
  }
}

function* fetchNavEffectSaga() {
  try {
    let { data } = yield call(navigation);
    yield put(fetchNavCompleted(data));
  } catch (e) {
    yield put(fetchNavFailed());
  }
}

function* acceptTermsEffectSaga() {
  try {
    let { data } = yield call(acceptTerms, true);
    yield put(acceptTermsCompleted(data));
  } catch (e) {
    yield put(acceptTermsFailed());
  }
}

function* fetchLanguagesEffectSaga() {
  try {
    let { data } = yield call(languages);
    yield put(fetchLanguagesCompleted(data));
  } catch (e) {
    yield put(fetchLanguagesFailed());
  }
}

function* fetchTimezonesEffectSaga() {
  try {
    let { data } = yield call(timezones);
    yield put(fetchTimezonesCompleted(data));
  } catch (e) {
    yield put(fetchTimezonesFailed());
  }
}

export function* fetchMeWatcherSaga() {
  yield takeLatest(fetchMeRequested.toString(), fetchMeEffectSaga);
}

export function* fetchNavWatcherSaga() {
  yield takeLatest(fetchNavRequested.toString(), fetchNavEffectSaga);
}

export function* acceptTermsWatcherSaga() {
  yield takeLatest(acceptTermsRequested.toString(), acceptTermsEffectSaga);
}

export function* fetchLanguagesWatcherSaga() {
  yield takeLatest(
    fetchLanguagesRequested.toString(),
    fetchLanguagesEffectSaga,
  );
}

export function* fetchTimezonesWatcherSaga() {
  yield takeLatest(
    fetchTimezonesRequested.toString(),
    fetchTimezonesEffectSaga,
  );
}

export default function* settingsRootSaga() {
  yield all([
    fetchMeWatcherSaga(),
    fetchNavWatcherSaga(),
    acceptTermsWatcherSaga(),
    fetchLanguagesWatcherSaga(),
    fetchTimezonesWatcherSaga(),
  ]);
}
