import { createSelector } from 'reselect';

const selectedControlApplications = state =>
  state.default.controlApplications.controlApplications;
const selectedControlApplication = state =>
  state.default.controlApplications.controlApplication;
const selectedControlApplicationsTotal = state =>
  state.default.controlApplications.total;

export const selectControlApplications = createSelector(
  [selectedControlApplications],
  controlApplications => controlApplications,
);

export const selectControlApplication = createSelector(
  [selectedControlApplication],
  controlApplication => controlApplication,
);

export const selectControlTotal = createSelector(
  [selectedControlApplicationsTotal],
  total => total,
);
