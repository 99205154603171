// import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import Api from 'common/services/Api';
import { NetworkAsset } from 'mss-component-library';

export { fetchVlans, fetchVlansMoc } from './elastic/Vlans';
export { fetchInterfacePacketMetrics } from './elastic/InterfacePacketMetrics';
export { fetchCpuMemoryData } from './elastic/CpuMemoryMetrics';
export { fetchSystemData, fetchPsuData } from './elastic/System';
export { fetchClockinfoMetric } from './elastic/TimeServices';
export {
  fetchSyslogData,
  fetchSyslogFilters,
  fetchSyslogDetail,
} from './elastic/Syslog';
export {
  fetchNetworkInterfaces,
  fetchNetworkInterfacesMoc,
} from './elastic/NetworkInterfaces';
export {
  fetchUptimeData,
  fetchCpuMemoryLastValue,
  fetchTempData,
} from './elastic/Overview';

export async function fetchNetworkAsset(
  assetId: string,
): Promise<NetworkAsset[]> {
  return Api.get(`/assets/${assetId}/`);
}

export async function fetchNetworkAssets(parameters: {
  site: string;
}): Promise<NetworkAsset[]> {
  parameters['type'] = 'network';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}
