import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchDashboards } from 'dashboards/services/DashboardService';
import {
  fetchDashboardsCompleted,
  fetchDashboardsRequested,
  fetchDashboardsFailed,
} from './slice';

function* fetchDashboardsEffectSaga() {
  try {
    let { data } = yield call(fetchDashboards);
    yield put(fetchDashboardsCompleted(data));
  } catch (e) {
    yield put(fetchDashboardsFailed());
  }
}

export function* fetchDashboardsWatcherSaga() {
  yield takeLatest(
    fetchDashboardsRequested.toString(),
    fetchDashboardsEffectSaga,
  );
}

export default function* dashboardsRootSaga() {
  yield all([fetchDashboardsWatcherSaga()]);
}
