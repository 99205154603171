import { createSlice, createAction } from '@reduxjs/toolkit';
import { arrayToObject } from 'common/utils';

// List iiot asset
export const fetchIiotAssetsRequested = createAction<{
  start: string;
  end: string;
}>('iiotAssets/fetchIiotAssetsRequested');
export const fetchIiotAssetsFailed = createAction(
  'iiotAssets/fetchIiotAssetsFailed',
);

// Single iiot asset
export const fetchIiotAssetRequested = createAction<{
  id: string;
  start: string;
  end: string;
}>('iiotAssets/fetchIiotAssetRequested');
export const fetchIiotAssetFailed = createAction(
  'iiotAssets/fetchIiotAssetFailed',
);

// Iiot asset by query ie. start end
export const fetchIiotAssetByQueryRequested = createAction(
  'iiotAssets/fetchIiotAssetRequested',
);
export const fetchIiotAssetByQueryFailed = createAction(
  'iiotAssets/fetchIiotAssetFailed',
);

const modalsSlice = createSlice({
  name: 'iiotAssets',
  initialState: {
    iiotAssets: [],
    iiotAsset: {},
    total: 0,
    iiotAssetsById: {},
  },
  reducers: {
    fetchIiotAssetsCompleted(state, action) {
      state.iiotAssets = action.payload.iiotAssets;
      state.iiotAssetsById = arrayToObject(action.payload.iiotAssets, '_id');
      state.total = action.payload.total;
    },
    fetchIiotAssetCompleted(state, action) {
      state.iiotAsset = action.payload;
    },
  },
});

export const {
  fetchIiotAssetCompleted,
  fetchIiotAssetsCompleted,
} = modalsSlice.actions;

export default modalsSlice.reducer;
