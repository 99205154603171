import { createSlice, createAction } from '@reduxjs/toolkit';

export const fetchMeFailed = createAction('settings/fetchMeFailed');
export const fetchMeRequested = createAction('settings/fetchMeRequested');

export const fetchNavFailed = createAction('settings/fetchNavFailed');
export const fetchNavRequested = createAction('settings/fetchNavRequested');

export const acceptTermsFailed = createAction('settings/acceptTermsFailed');
export const acceptTermsRequested = createAction(
  'settings/acceptTermsRequested',
);

export const fetchLanguagesFailed = createAction(
  'settings/fetchLanguagesFailed',
);
export const fetchLanguagesRequested = createAction(
  'settings/fetchLanguagesRequested',
);

export const fetchTimezonesFailed = createAction(
  'settings/fetchTimezonesFailed',
);
export const fetchTimezonesRequested = createAction(
  'settings/fetchTimezonesRequested',
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    me: {},
    nav: [],
    languages: [],
    timezones: [],
  },
  reducers: {
    fetchMeCompleted(state, action) {
      return { ...state, me: action.payload };
    },
    fetchNavCompleted(state, action) {
      return { ...state, nav: action.payload };
    },
    acceptTermsCompleted(state, action) {
      return { ...state, me: action.payload };
    },
    fetchLanguagesCompleted(state, action) {
      return { ...state, languages: action.payload };
    },
    fetchTimezonesCompleted(state, action) {
      return { ...state, timezones: action.payload };
    },
  },
});

export const {
  fetchMeCompleted,
  fetchNavCompleted,
  acceptTermsCompleted,
  fetchLanguagesCompleted,
  fetchTimezonesCompleted,
} = settingsSlice.actions;

export default settingsSlice.reducer;
