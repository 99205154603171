import React from 'react';
import {
  objectArraySort,
  Flex,
  RemoteAccessProfile,
  RequestStatus,
  MssIcon,
  IconStartConnection,
} from 'mss-component-library';
import { Tooltip, Button } from 'antd';
import { TFunction } from 'i18next';
import dateFormat from 'dateformat';
import styled from 'styled-components';

const RowAction = styled(Button)`
  justify-content: center;
  border: none;
  &:hover,
  &:active {
    background: ${p => p.theme.colors.common.grey400};
  }
`;

export const RowActionButton = styled(RowAction)`
  height: 24;
  width: 24;
`;

const columns = (t: TFunction, openConnectModal: any) => [
  {
    title: t('Target asset'),
    dataIndex: ['asset_name'],
    key: 'asset_name',
    sorter: (a: any, b: any) => objectArraySort(a, b, 'asset_name'),
  },
  {
    title: t('Profile'),
    dataIndex: 'profile_object',
    key: 'profile',
    width: 165,
    sorter: (a: any, b: any) => objectArraySort(a, b, 'profile'),
    render: (profile_object: RemoteAccessProfile) => (
      <>
        {profile_object.protocol.toUpperCase()} - {profile_object.name}
      </>
    ),
  },
  {
    title: t('Request date'),
    dataIndex: 'created_at',
    key: 'created_at',
    width: 165,
    sorter: (a: any, b: any) => objectArraySort(a, b, 'created_at'),
    render: (record: any) =>
      record && dateFormat(record || '', 'mmmm dS, yyyy @ h:MM:ss TT', true),
  },
  {
    title: t('Status'),
    dataIndex: 'status',
    key: 'status',
    width: 150,
    sorter: (a: any, b: any) => objectArraySort(a, b, 'status'),
    render: (status: string) => <RequestStatus status={status} />,
  },
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    width: 40,
    render: (record: any) => {
      return (
        <Flex onClick={e => e.stopPropagation()}>
          {record.can_start_session && record.status === 'allow' && (
            <Tooltip title={t('Connect to asset')}>
              <RowActionButton
                onClick={() => {
                  openConnectModal(record);
                }}
                shape="circle"
                style={{ border: 'none' }}
              >
                <MssIcon icon={IconStartConnection} className="mr-1" />
              </RowActionButton>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
];

export default columns;
