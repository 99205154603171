// import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import Api from 'common/services/Api';
import { PlcDcsAsset } from 'mss-component-library';
import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import { parseDateRange } from 'common/utils';

export async function fetchPlcMetricsCPU({ assetId, index }) {
  const query = {
    size: 1,
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    _source: {
      includes: [
        'Cpu_MinLoad',
        'Cpu_MaxLoad',
        'Cpu_LoadPercentage',
        'mss.collector_type',
        'mss.name',
        '@timestamp',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'cpu',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsECC({ assetId, index, position }) {
  const query = {
    size: 1,
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    _source: {
      includes: [
        'Ecc_Position',
        'Ecc_ErrorCounter',
        'mss.collector_type',
        'mss.name',
        '@timestamp',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'ecc',
            },
          },
          {
            match: {
              Ecc_Position: position,
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsHKU({ assetId, index, position }) {
  const query = {
    size: 1,
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    _source: {
      includes: [
        'Hku_TempAirOut',
        'Hku_TempBattery',
        'Hku_Position',
        'Hku_TempAirIn',
        'Hku_VoltageBattery',
        'Hku_VoltageInput',
        'mss.collector_type',
        'mss.name',
        '@timestamp',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'hku',
            },
          },
          {
            match: {
              Hku_Position: position,
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsVNet({ assetId, index }) {
  const query = {
    size: 1,
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    _source: {
      includes: [
        'Vnet_Rx_Nt_M',
        'Vnet_Rx_BB',
        'Vnet_Tx_TDR',
        'Vnet_LoadPercentage',
        'Vnet_Rx_Scan',
        'Vnet_DE_BEto',
        'Vnet_VE_DipP',
        'Vnet_TE_MTto',
        'port',
        'Vnet_VE_Swto',
        'Vnet_TE_Fudr',
        'Vnet_DE_Hung',
        'Vnet_TE_cals',
        'Vnet_Rx_Mtkn',
        'Vnet_DE_BRto',
        'Vnet_RX_BRCV',
        'Vnet_RE_leng',
        'Vnet_Tx_ReAd',
        'Vnet_Rx_ReAd',
        'Vnet_RE_Crc',
        'Vnet_Rx_Time',
        'Vnet_Tx_Ihty',
        'Vnet_TM_Scal',
        'Vnet_TE_Leng',
        'Vnet_DE_Fifo',
        'Vnet_TE_MTfe',
        'Vnet_Tx_Time',
        'Vnet_TE_Cand',
        'Vnet_DE_Pari',
        'Vnet_Rx_Ihty',
        'Vnet_TE_Coll',
        'Vnet_RE_Fovr',
        'Vnet_Tx_Dtkn',
        'Vnet_VE_SrmP',
        'Vnet_Rx_AdSp',
        'Vnet_Rx_AlSp',
        'Vnet_RE_Sum',
        'Vnet_RE_fomt',
        'Vnet_VE_Alu',
        'Vnet_TX_BRCV',
        'Vnet_CWT_ovr',
        'Vnet_DE_Mark',
        'Vnet_RX_CWTO',
        'Vnet_Tx_CNR',
        'Vnet_RE_RUNT',
        'Vnet_Tx_Busf',
        'Vnet_Tx_ReNt',
        'Vnet_VE_MM',
        'Vnet_Rx_Busf',
        'Vnet_RE_HapL',
        'Vnet_Tx_BB',
        'Vnet_DE_rtyE',
        'Vnet_rsv',
        'Vnet_TM_Ikki',
        'Vnet_Rx_Dtkn',
        'Vnet_RE_FUdr',
        'Vnet_LB_rtry',
        'Vnet_RX_Ntkn',
        'Vnet_RE_RBNR',
        'Vnet_DE_rtry',
        'Vnet_TM_RWTO',
        'Vnet_DE_LVL',
        'Vnet_Rx_Hap',
        'Vnet_DE_SCTL',
        'Vnet_Tx_HPAN',
        'Vnet_Rx_CNR',
        'mss.collector_type',
        'mss.name',
        '@timestamp',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'vnet',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsWAC({ assetId, index }) {
  const query = {
    size: 1,
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    _source: {
      includes: [
        'Wac_Comm Load Ave',
        'Wac_Comm Load Cur',
        'Wac_Configured Limit',
        'Wac_Throughput',
        'mss.collector_type',
        'mss.name',
        '@timestamp',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'wac',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

// Revision info

export async function fetchPlcMetricsCPUCardStatus({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'cpu_card_status',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsCPULoad({ assetId, index }) {
  const query = {
    size: 1,
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    _source: {
      includes: [
        'Cpu_MinLoad',
        'Cpu_MaxLoad',
        'Cpu_LoadPercentage',
        'mss.collector_type',
        'mss.asset_name',
        '@timestamp',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'cpu',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsCPUInventory({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'cpu_inventory',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: [
                  'card_style',
                  'card_type',
                  'hardware_revision',
                  'boot_revision',
                  'vehicle_revision',
                  'io_co_pro_revision',
                ],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsPSUCardStatus({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'psu_card_status',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsBatteryStatus({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'battery',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsVoltage({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'voltage',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsFanStatus({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'fan',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchPlcMetricsAirTemperature({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'temperature_air',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchPlcMetricsBatteryTemperature({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'temperature_battery',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsInventory({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'inventory',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 1,
          _source: {
            excludes: ['host', 'mss', 'tags', '@timestamp', '@version', 'port'],
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsCOMCardStatus({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'com_card_status',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

// Nodes and Slots
export function fetchAllNodes({ index, assetId }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'node',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-24h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'node_no',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                excludes: [
                  'mss',
                  'tags',
                  '@timestamp',
                  '@version',
                  'host',
                  'station_name',
                  'address',
                  'port',
                ],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export function fetchSlotsPerNode({ index, assetId, nodeId }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'slot',
            },
          },
          {
            match: {
              node_number: nodeId,
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-24h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'slot_no',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                excludes: [
                  'mss',
                  'tags',
                  '@timestamp',
                  '@version',
                  'host',
                  'station_name',
                  'address',
                  'port',
                  'node_number',
                  'line_number',
                ],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export function fetchUnitStatus({ index, assetId }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'unit_status',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'position',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['value'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export function fetchIOModule({ index, assetId }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'io_module',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-24h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'unit_no',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                excludes: [
                  'mss',
                  'tags',
                  '@timestamp',
                  '@version',
                  'host',
                  'station_name',
                  'address',
                  'port',
                ],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcMetricsSafety({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'safety',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 1,
          _source: {
            excludes: ['host', 'mss', 'tags', '@timestamp', '@version', 'port'],
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcCatalogueCPUInformation({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'cpu_information',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 1,
          _source: {
            includes: [
              'type_left',
              'h_w_serial_no_left',
              'syscard_serial_no_left',
              'os_revision_left',
              'type_right',
              'h_w_serial_no_right',
              'syscard_serial_no_right',
              'os_revision_right',
            ],
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchPlcMetricsCPUStatus({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'cpu_status',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 1,
          _source: {
            includes: ['cpu_status_left', 'cpu_status_right', 'duplex_status'],
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchPlcMetricsAPCSettings({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'apc_settings',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 1,
          _source: {
            includes: ['auto_start', 'apc_mode'],
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchPlcCatalogueCPUM({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'stardom_configuration_cpum',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 1,
          _source: {
            includes: [
              'sbbus_double',
              'bus_type',
              'retain_sram',
              'java_vm',
              'iom_autoload',
              'protect_prj',
              'lre_enable',
              'net_p1_stby_ip',
              'net_p2_ctrl_ip',
              'net_p2_stby_ip',
              'net_p2_mask',
              'net_p3_ip',
              'net_p3_mask',
              'net_p4_ip',
              'net_p4_mask',
              'nfcp500_duplex',
              'autoapc_enable',
              'use_sd_card',
              'ena_sd_passwd',
            ],
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchPlcCatalogueModules({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'stardom_configuration_module',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      unit_number: {
        terms: {
          field: 'unit_no.keyword',
        },
        aggs: {
          slot_number: {
            terms: {
              field: 'slot_no.keyword',
            },
            aggs: {
              top_uids_hits: {
                top_hits: {
                  sort: [
                    {
                      '@timestamp': {
                        order: 'desc',
                      },
                    },
                  ],
                  size: 1,
                  _source: {
                    includes: ['unit_no', 'slot_no', 'module_name'],
                  },
                },
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchPlcCatalogueInformation({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'stardom_information',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 1,
          _source: {
            includes: [
              'host_name',
              'ip_address',
              'subnet_mask',
              'mac_address',
              'type',
              'h_w_serial_no',
              'syscard_serial_no',
              'os_revision',
              'bootrom_revision',
              'jeros_revision',
              'main_memory_total',
              'sram_memory',
              'flash_memory_total',
              'flash_memory_free',
              'date_and_time',
              'timezone',
              'status',
            ],
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchFCNLogs({
  index,
  id,
  filter,
  start,
  end,
  sort,
  search,
  pagination,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);

  const query = {
    ...pagination,
    sort,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_key': 'stardom_logs',
            },
          },
        ],
        filter: {
          range: {
            date_time: {
              gte: `${startMoment.format('YYYY/MM/DD hh:mm:ss')}`,
              lte: `${endMoment.format('YYYY/MM/DD hh:mm:ss')}`,
            },
          },
        },
      },
    },
    _source: {
      includes: ['date_time', 'log_type', 'log_source', 'message'],
    },
  };
  return fetchElasticQuery(index, query);
}
export async function fetchUsers({ index, id, pagination }) {
  const query = {
    ...pagination,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_key': 'stardom_users',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      top_uids: {
        terms: {
          field: 'user.keyword',
          size: 5000,
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['user', 'flags'],
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPlcDcsAsset(
  assetId: string,
): Promise<PlcDcsAsset[]> {
  return Api.get(`/assets/${assetId}/`);
}

export async function fetchPlcDcsAssets(parameters: {
  site: string;
}): Promise<PlcDcsAsset[]> {
  parameters['type'] = 'dcs_plc';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}
