import React from 'react';

import styled, { keyframes } from 'styled-components';

const bounceDelay = keyframes`
0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Bounce = styled.div`
  width: 12px;
  height: 12px;
  background-color: var(--primary);

  border-radius: 100%;
  display: inline-block;
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;

  &.bounce1 {
    animation-delay: -0.32s;
  }
  &.bounce2 {
    animation-delay: -0.16s;
  }
  &.bounce3 {
    animation-delay: -0.8s;
  }
`;

const Spinner = styled.div`
  text-align: center;
`;

function Loading() {
  return (
    <Spinner>
      <Bounce className="bounce1"></Bounce>
      <Bounce className="bounce2"></Bounce>
      <Bounce className="bounce3"></Bounce>
    </Spinner>
  );
}

export default Loading;
