import { createSlice, createAction } from '@reduxjs/toolkit';

export const fetchEnvAssetsRequested = createAction<{ query?: object }>(
  'envAssets/fetchEnvAssetsRequested',
);

export const fetchEnvAssetsFailed = createAction(
  'envAssets/fetchEnvAssetsFailed',
);

export const fetchEnvAssetRequested = createAction<string>(
  'envAssets/fetchEnvAssetRequested',
);

export const fetchEnvAssetFailed = createAction(
  'envAssets/fetchEnvAssetFailed',
);

const envAssets = createSlice({
  name: 'envAssets',
  initialState: {
    envAsset: {},
    envAssets: [],
    total: 0,
  },
  reducers: {
    fetchEnvAssetsCompleted(state, action) {
      state.envAssets = action.payload;
    },
    fetchEnvAssetCompleted(state, action) {
      state.envAsset = action.payload;
    },
  },
});

export const {
  fetchEnvAssetsCompleted,
  fetchEnvAssetCompleted,
} = envAssets.actions;

export default envAssets.reducer;
