import { createAction, createSlice } from '@reduxjs/toolkit';

export const fetchCenterRequested = createAction(
  'configuration/fetchCenterRequested',
);
export const fetchCenterFailed = createAction(
  'configuration/fetchCenterFailed',
);
export const fetchCenterTermsRequested = createAction(
  'configuration/fetchCenterTermsRequested',
);
export const fetchCenterTermsFailed = createAction(
  'configuration/fetchCenterTermsFailed',
);

const configurationSlice = createSlice({
  name: 'configuration',
  initialState: {
    center: {},
  },
  reducers: {
    fetchCenterCompleted(state, action) {
      return {
        ...state,
        center: action.payload,
      };
    },
    fetchCenterTermsCompleted(state, action) {
      return {
        ...state,
        center: action.payload,
      };
    },
    updateCenterCompleted(state, action) {
      return { ...state, center: action.payload };
    },
  },
});

export const {
  fetchCenterCompleted,
  fetchCenterTermsCompleted,
  updateCenterCompleted,
} = configurationSlice.actions;

export default configurationSlice.reducer;
