import { useSelector } from 'react-redux';
import { selectSecurityApp } from 'securityApps/store/selectors';

function SecurityApplicationBreadcrumb() {
  const networkAsset: any = useSelector(selectSecurityApp);

  return networkAsset?.name || null;
}

export default SecurityApplicationBreadcrumb;
