import { createSlice, createAction } from '@reduxjs/toolkit';

export const fetchNetworkAssetsRequested = createAction<{ query?: object }>(
  'networkAssets/fetchNetworkAssetsRequested',
);

export const fetchNetworkAssetsFailed = createAction(
  'networkAssets/fetchNetworkAssetsFailed',
);

export const fetchNetworkAssetRequested = createAction<string>(
  'computeAssets/fetchNetworkAssetRequested',
);

export const fetchNetworkAssetFailed = createAction(
  'computeAssets/fetchNetworkAssetFailed',
);

const networkAssets = createSlice({
  name: 'networkAssets',
  initialState: {
    networkAsset: {},
    networkAssets: [],
    total: 0,
  },
  reducers: {
    fetchNetworkAssetsCompleted(state, action) {
      state.networkAssets = action.payload;
    },
    fetchNetworkAssetCompleted(state, action) {
      state.networkAsset = action.payload;
    },
  },
});

export const {
  fetchNetworkAssetsCompleted,
  fetchNetworkAssetCompleted,
} = networkAssets.actions;

export default networkAssets.reducer;
