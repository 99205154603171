import { createSlice, createAction } from '@reduxjs/toolkit';

export const fetchControlApplicationsRequested = createAction<{
  query?: object;
}>('controlApplications/fetchControlApplicationsRequested');

export const fetchControlApplicationsFailed = createAction(
  'controlApplications/fetchControlApplicationsFailed',
);

export const fetchControlApplicationRequested = createAction<string>(
  'controlApplications/fetchControlApplicationRequested',
);

export const fetchControlApplicationFailed = createAction(
  'controlApplications/fetchControlApplicationFailed',
);

const controlApplications = createSlice({
  name: 'controlApplications',
  initialState: {
    controlApplication: {},
    controlApplications: [],
    total: 0,
  },
  reducers: {
    fetchControlApplicationsCompleted(state, action) {
      state.controlApplications = action.payload;
    },
    fetchControlApplicationCompleted(state, action) {
      state.controlApplication = action.payload;
    },
  },
});

export const {
  fetchControlApplicationsCompleted,
  fetchControlApplicationCompleted,
} = controlApplications.actions;

export default controlApplications.reducer;
