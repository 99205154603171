import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import get from 'lodash/get';
import { fetchSitesRequested, selectSite } from 'sites/store/slice';
import { selectedSite, selectedSites } from 'sites/store/selectors';
import { loadingSelector } from 'common/store/selectors';
import { selectMe } from 'settings/store/selectors';
import { loadState, saveState } from 'store/localStorage';
import { useTranslation } from 'react-i18next';

const customStyles = {
  container: style => ({
    ...style,
    zIndex: '3',
  }),
  control: style => ({
    ...style,
    backgroundColor: '#004f9b',
    borderColor: '#FFFFFF',
    color: '#FFFFFF',
    '&:hover': {
      borderColor: '#FFFFFF',
    },
  }),
  input: style => ({
    ...style,
    color: '#FFFFFF',
  }),
  valueContainer: style => ({
    ...style,
    color: '#FFFFFF',
  }),
  singleValue: style => ({
    ...style,
    color: '#FFFFFF',
  }),
  placeholder: style => ({
    ...style,
    color: '#FFFFFF',
  }),
};

const theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#004f9b',
  },
});

function SiteSelector({ history }) {
  const [t] = useTranslation('sites');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSitesRequested());
  }, [dispatch]);

  const user = useSelector(selectMe);
  const sites = user.sites;
  const site = useSelector(selectedSite);
  const loading = useSelector(loadingSelector(['sites/fetchSites']));

  const options = sites?.map(site => ({ label: site.name, value: site }));

  const value = options.filter(
    option => get(option, 'value.id') === get(site, 'id'),
  );

  const onChangeHandler = ({ value }) => {
    dispatch(selectSite(value));
    history.push(`/site/${value.id}`);
  };

  const goToSitemap = useCallback(() => {
    const currentState = loadState();
    const newState = {
      ...currentState,
      default: { ...currentState?.default, site: undefined },
    };
    saveState(newState);
    dispatch(selectSite({}));
    history.push('/sites/map');
  }, [dispatch, history]);

  const groupedOptions = [
    {
      label: t('Sitemap'),
      options: options,
    },
  ];

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const formatGroupLabel = data => {
    return (
      <div style={groupStyles} onClick={goToSitemap} className="cursor-pointer">
        <span>{data.label}</span>
        <span
          style={{
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
          }}
        >
          {data.options.length}
        </span>
      </div>
    );
  };

  return (
    <Select
      isLoading={loading}
      value={value}
      placeholder={t('Select a site')}
      data-ta="SiteSelector"
      styles={customStyles}
      isClearable={false}
      isSearchable
      onChange={onChangeHandler}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      theme={theme}
    />
  );
}

export default SiteSelector;
