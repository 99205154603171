import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import { parseDateRange, calculateBuckets } from 'common/utils';

export async function fetchCpuMemoryData({ id, index, start, end }) {
  /**
   *  Get CPU and Memory avarages
   */
  const { startMoment, endMoment } = parseDateRange(start, end);
  const buckets = calculateBuckets(startMoment, endMoment);
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_key': 'cpu_memory',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: `${startMoment.format()}`,
              lte: `${endMoment.format()}`,
            },
          },
        },
      },
    },
    aggs: {
      data: {
        auto_date_histogram: {
          field: '@timestamp',
          buckets: buckets,
          minimum_interval: 'hour',
        },
        aggs: {
          avg_cpu_load: {
            avg: {
              field: 'snmp.avg_cpu_load',
            },
          },
          avg_memory_allocated: {
            avg: {
              field: 'snmp.avg_memory_allocated',
            },
          },
          avg_memory_free: {
            avg: {
              field: 'snmp.avg_memory_free',
            },
          },
          avg_memory_load: {
            bucket_script: {
              buckets_path: {
                total: 'avg_memory_allocated',
                used: 'avg_memory_free',
              },
              script: {
                source:
                  'params.total != null && params.total > 0 ? (params.total/params.used) * 100 : null',
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
