import { DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RangeValue } from 'rc-picker/lib/interface';

const staticDefaultValue: RangeValue<moment.Moment> = [
  moment().add(-1, 'month'),
  moment(),
];

export const formatRange = (values: RangeValue<moment.Moment>) => {
  const [from, to] = values || [];
  return {
    from: from?.startOf('day').toISOString(),
    to: to?.endOf('day').toISOString(),
  };
};

interface Props {
  onChange: (range: { to?: string; from?: string }) => void;
  style?: React.CSSProperties;
  ranges?: any;
  showTime?: boolean;
  disabledTime?: any;
  disabledDate?: any;
  defaultValue?: any;
  allowClear?: any;
}

export default function RangePicker({
  onChange,
  defaultValue,
  allowClear = true,
  ...rest
}: Props) {
  const [t] = useTranslation(['activityLog', 'common']);
  const [range, setRange] = useState<RangeValue<moment.Moment>>(
    defaultValue || staticDefaultValue,
  );

  useEffect(() => {
    onChange(formatRange(range));
  }, [range, onChange]);

  const ranges: { [x: string]: [moment.Moment, moment.Moment] } = {
    [t('Today')]: [moment(), moment()],
    [t('Last 7 days')]: [moment().add(-7, 'day'), moment()],
    [t('Last 14 days')]: [moment().add(-14, 'day'), moment()],
    [t('Last month')]: [moment().add(-1, 'month'), moment()],
    [t('Last 3 months')]: [moment().add(-3, 'month'), moment()],
  };

  return (
    <DatePicker.RangePicker
      showTime={false}
      disabledDate={d => !d || d.isAfter(moment())}
      defaultValue={range}
      ranges={ranges}
      onChange={setRange}
      allowClear={allowClear}
      {...rest}
    />
  );
}
