import styled, { css } from 'styled-components';

export default styled.button`
  margin-top: 0;
  padding: 0.375rem 0.75rem;
  line-height: 2.25;
  border-radius: 3px;
  transition: 0.2s ease;
  min-width: 200px;
  font-size: 14px;

  ${p =>
    p.color === 'primary' &&
    css`
      color: white;
      background: #467fcf;
      border-color: #467fcf;

      &:not(:disabled):hover {
        background: #004f9b;
        border-color: #004f9b;
      }
    `}

  ${p =>
    p.color === 'grey' &&
    css`
      color: rgba(0, 0, 0, 0.67);
      background: white;
      border-color: transparent;

      &:not(:disabled):hover {
        background: #f7f7f7;
        border-color: lightgrey;
      }
    `}

&:disabled {
    filter: saturate(0.6);
    opacity: 0.4;
    cursor: default;
  }
`;
