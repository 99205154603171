import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchIIotAssets,
  fetchIIotAsset,
} from 'iiotAssets/services/IiotAssetService';
import {
  fetchIiotAssetsCompleted,
  fetchIiotAssetsFailed,
  fetchIiotAssetsRequested,
  fetchIiotAssetCompleted,
  fetchIiotAssetRequested,
} from './slice';

function* fetchIIotAssetsEffectSaga(action) {
  try {
    let { data } = yield call(fetchIIotAssets, action.payload);

    const iiotAssets = get(data, 'hits.hits');

    yield put(fetchIiotAssetsCompleted(iiotAssets));
  } catch (e) {
    yield put(fetchIiotAssetsFailed());
  }
}

export function* fetchIIotAssetsWatcherSaga() {
  yield takeLatest(
    fetchIiotAssetsRequested.toString(),
    fetchIIotAssetsEffectSaga,
  );
}

function* fetchIIotAssetEffectSaga(action) {
  try {
    let { data } = yield call(fetchIIotAsset, action.payload);

    const iiotAsset = get(data, 'hits.hits[0]');

    yield put(fetchIiotAssetCompleted(iiotAsset));
  } catch (e) {
    yield put(fetchIiotAssetsFailed());
  }
}

export function* fetchIIotAssetWatcherSaga() {
  yield takeLatest(
    fetchIiotAssetRequested.toString(),
    fetchIIotAssetEffectSaga,
  );
}

export default function* iiotAssetsRootSaga() {
  yield all([fetchIIotAssetsWatcherSaga(), fetchIIotAssetWatcherSaga()]);
}
