import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
export async function fetchComputeAssetsPatchEvents_al({
  id,
  hotfixId,
  index,
}) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': `${id}`,
            },
          },
          {
            match: {
              'json.name': 'pack_mss-win-inventory_patches',
            },
          },
          {
            match: {
              'json.columns.hotfix_id': `${hotfixId}`,
            },
          },
        ],
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          // sort: [
          //   {
          //     '@timestamp': {
          //       order: 'desc',
          //     },
          //   },
          // ],
          size: 15,
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
