import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const currentYear = new Date().getFullYear();
  const [t] = useTranslation('common');
  return (
    <footer className="py-4 bg-light">
      <div className="container d-flex justify-content-between align-items-center">
        <div>
          <small>{t('CopyrightFooter', { year: currentYear })}</small>
        </div>
        <div>
          <ul className="list-inline list-inline-dots mb-0">
            <li className="list-inline-item">
              <Link to="/release-notes">{t('Release notes')}</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
