import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import { parseDateRange, calculateBucketsPerHour } from 'common/utils';

export async function fetchContactData({ id, index, start, end }) {
  /**
   *  Get Contact averages
   */
  const { startMoment, endMoment } = parseDateRange(start, end);
  const buckets = calculateBucketsPerHour(startMoment, endMoment, 300, 4);
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_type': 'odu',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: `${startMoment.format()}`,
              lte: `${endMoment.format()}`,
            },
          },
        },
      },
    },
    aggs: {
      data: {
        auto_date_histogram: {
          field: '@timestamp',
          buckets: buckets,
          minimum_interval: 'minute',
        },
        aggs: {
          avg_sensor_contact: {
            avg: {
              field: 'Sensor_Contact',
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
