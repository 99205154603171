import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
export async function fetchComputeGroupEvents_ab({ id, groupName, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': `${id}`,
            },
          },
          {
            match: {
              'json.name': 'pack_mss-win-inventory_groups',
            },
          },
          {
            match: {
              'json.columns.groupname': `${groupName}`,
            },
          },
          {
            match: {
              'json.action': 'added',
            },
          },
        ],
      },
    },
    aggs: {
      'top-uids': {
        terms: {
          field: 'json.columns.groupname',
          size: 5000,
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  'json.counter': {
                    order: 'desc',
                  },
                  'json.action': {
                    order: 'asc',
                  },
                },
              ],
              size: 1,
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
