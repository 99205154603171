import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  fullscreen?: boolean;
}

interface ContainerProps {
  fullscreen?: boolean;
}

const Container = styled.div`
  ${(p: ContainerProps) =>
    p.fullscreen &&
    css`
      display: flex;
      height: 100vh;
      width: 100vw;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`;

function NotFound({ fullscreen }: Props) {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  const [t] = useTranslation('common');

  return (
    <Container fullscreen={fullscreen}>
      <div className="display-2 text-muted mb-3">404</div>
      <h1 className="h2 mb-3">
        {t('Oops&hellip; The page you are looking for is not here')}
      </h1>
      <p className="h4 text-muted font-weight-normal mb-5">
        {t('Please go to another page or back')}
      </p>
      <button className="btn btn-primary" onClick={goBack}>
        <div className="d-flex align-items-center">
          <ArrowLeft size={18} className="mr-1" /> <span>{t('Go back')}</span>
        </div>
      </button>
    </Container>
  );
}

export default NotFound;
