import { sso } from 'settings/services/SettingsService';

export function getToken() {
  // Retrieves the user token from sessionStorage
  return sessionStorage.getItem('token');
}

export async function redirect(nextUrl?: string) {
  try {
    const response = await sso();
    const url = new URL(
      `${response.data.redirect}?next=${encodeURIComponent(nextUrl)}`,
    );
    window.location.href = url.href;
  } catch (error) {
    console.error(error);
  }
}
