import cn from 'classnames';
import Loading from 'common/components/Loading';
import ObjectTable from 'common/components/ObjectTable';
import useFetchElasticQuery from 'common/hooks/useFetchElasticQuery';
import EventLogLevel from 'computeAssets/components/EventLogLevel';
import { fetchComputeAssetEventLogDetail } from 'computeAssets/services/shared/FetchComputeAssetEventLogDetail';
import { fetchComputeAssetEventLogOccurence } from 'computeAssets/services/shared/FetchComputeAssetEventLogOccurence';
import get from 'lodash/get';
import has from 'lodash/has';
import moment from 'moment';
import { ComputeAssetEvent } from 'mss-component-library';
import React, { useState } from 'react';
import { FileText, Search } from 'react-feather';
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import assetIndex from 'common/utils/queryIndexBuilder';
import { selectedSite } from 'sites/store/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface EventLogsModalProps {
  id: string;
  event: ComputeAssetEvent;
  isAgentBased: boolean;
  closeModal: () => void;
}

function EventLogsModal({
  id,
  event,
  isAgentBased,
  closeModal,
}: EventLogsModalProps) {
  const [t] = useTranslation('assetsCompute');
  const [activeTab, setActiveTab] = useState<number>(1);
  const recordId = get(event, 'winlog.record_id');
  const eventId = get(event, 'winlog.event_id');
  const { id: siteId } = useSelector(selectedSite);

  const index = assetIndex({
    siteId,
    endpoint: 'logs',
    type: 'compute',
    subType: isAgentBased ? 'ab' : 'al',
    wildCard: true,
  });

  const [loadingDetail, detailData] = useFetchElasticQuery(
    fetchComputeAssetEventLogDetail,
    'fetchComputeAssetEventLogDetail',
    {
      id,
      recordId,
      index,
      timestamp: isAgentBased ? undefined : '@timestamp',
    },
    { refetchInterval: false },
  );
  const [loadingOccurance, occuranceData] = useFetchElasticQuery(
    fetchComputeAssetEventLogOccurence,
    'fetchComputeAssetEventLogOccurence',
    {
      id,
      eventId,
      timestamp: isAgentBased ? undefined : '@timestamp',
      index,
    },
    { refetchInterval: false },
  );

  if (loadingDetail || loadingOccurance) return <Loading />;
  const detailSource = get(detailData, 'hits.hits[0]._source');

  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        <div>
          <EventLogLevel logLevel={get(event, 'log.level')} />{' '}
          {`${t('Event')}: ${get(event, 'winlog.event_id')}, ${get(
            event,
            'winlog.provider_name',
          )} `}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="border">
          <Nav tabs className="mb-4">
            <NavItem>
              <NavLink
                className={cn(
                  { active: activeTab === 1 },
                  'd-flex justify-content-between align-items-center',
                )}
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                <FileText size={14} className="mr-1" />
                {t('General')}
              </NavLink>
            </NavItem>
            {isAgentBased && (
              <NavItem>
                <NavLink
                  className={cn(
                    { active: activeTab === 2 },
                    'd-flex justify-content-between align-items-center',
                  )}
                  onClick={() => {
                    setActiveTab(2);
                  }}
                >
                  <Search size={14} className={'mr-1'} />
                  {t('Details')}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab} className="m-4">
            <TabPane tabId={1}>
              <Input
                type="textarea"
                name="text"
                disabled
                value={get(detailSource, 'message')}
                className="text-black"
                rows="10"
              />

              <div>
                <div className="form-group row mt-4">
                  <label
                    htmlFor="logName"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {`${t('Log name')}:`}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="logName"
                      value={get(detailSource, 'winlog.computer_name')}
                    />
                  </div>
                  <label
                    htmlFor="logged"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {t('Logged')}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="logged"
                      value={moment(get(detailSource, 'event.created')).format(
                        'LLLL',
                      )}
                    />
                  </div>
                </div>

                <div className="form-group row mt-4">
                  <label
                    htmlFor="source"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {t('Source')}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="source"
                      value={get(detailSource, 'winlog.provider_name')}
                    />
                  </div>
                  <label
                    htmlFor="category"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {t('Category')}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="category"
                      value={get(detailSource, 'event.action')}
                    />
                  </div>
                </div>

                <div className="form-group row mt-4">
                  <label
                    htmlFor="eventId"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {`${t('Event id')}:`}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="eventId"
                      value={get(detailSource, 'winlog.event_id')}
                    />
                  </div>
                  <label
                    htmlFor="keywords"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {`${t('Keywords')}:`}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="keywords"
                      value={
                        has(detailSource, 'winlog.keywords')
                          ? Array.isArray(get(detailSource, 'winlog.keywords'))
                            ? get(detailSource, 'winlog.keywords').join()
                            : get(detailSource, 'winlog.keywords')
                          : ''
                      }
                    />
                  </div>
                </div>

                <div className="form-group row mt-4">
                  <label
                    htmlFor="level"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {`${t('Level')}:`}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="level"
                      value={get(detailSource, 'log.level')}
                    />
                  </div>
                  <label
                    htmlFor="computer"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {t('Computer')}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="computer"
                      value={get(detailSource, 'winlog.computer_name')}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            {isAgentBased && (
              <TabPane tabId={2}>
                <ObjectTable data={get(detailSource, 'winlog')} />
              </TabPane>
            )}
          </TabContent>
        </div>
        <div className="mt-3">
          <em>Repeat Count Event ID{get(detailSource, 'winlog.event_id')}:</em>
          <strong>{get(occuranceData, 'hits.total.value')}</strong>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default EventLogsModal;
