import _ from 'lodash';
import { createSelector } from 'reselect';

const selectStartDate = state => state.default.common.start;
const selectEndDate = state => state.default.common.end;
const selectRefreshInterval = state => state.default.common.refreshInterval;
const selectRefreshCount = state => state.default.common.refreshCount;
const selectIsPaused = state => state.default.common.isPaused;

const selectModals = state => state.default.common.modals;

export const selectedModals = createSelector([selectModals], modals => modals);

export const selectDateRange = createSelector(
  [selectStartDate, selectEndDate],
  (start, end) => {
    return { start, end };
  },
);

export const selectDateRangePicker = createSelector(
  [
    selectStartDate,
    selectEndDate,
    selectRefreshInterval,
    selectIsPaused,
    selectRefreshCount,
  ],
  (start, end, refreshInterval, isPaused, refreshCount) => {
    return { start, end, refreshInterval, isPaused, refreshCount };
  },
);

export const loadingSelector = actions => state => {
  // returns true only when all actions is not loading
  return _(actions).some(action => {
    return _.get(state, `default.loading.${action}`, true);
  });
};
