import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
export async function fetchDiskEvents_ab({ id, deviceId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': `${id}`,
            },
          },
          {
            match: {
              'json.name': 'pack_mss-win-inventory_logical_drives',
            },
          },
          {
            match: {
              'json.columns.device_id': `${deviceId}`,
            },
          },
          {
            match: {
              'json.action': 'added',
            },
          },
        ],
      },
    },
    aggs: {
      top_uids_hits: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 15,
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}
