import Api from 'common/services/Api';
import { StatisticsParam } from 'dashboards/types';

const baseUrl = '/dashboard/';
export function fetchDashboards() {
  const params = {
    params: {
      deleted: false,
    },
  };
  return Api.get(baseUrl, params);
}

export function updateDashboard(dashboardId: string, body: any) {
  return Api.put(`${baseUrl}${dashboardId}/`, body);
}
export function patchDashboard(dashboardId: string, body: any) {
  return Api.patch(`${baseUrl}${dashboardId}/`, body);
}

export function addDashboard(body) {
  return Api.post(baseUrl, body);
}

export function deleteDashboard(dashboardId: string) {
  return Api.delete(`${baseUrl}${dashboardId}/`);
}

export function fetchStatistics(params: StatisticsParam, headers?: any) {
  return Api.get('/statistics/', {
    params,
    headers,
  });
}

export function setFavorite(body: any) {
  return Api.patch(`${baseUrl}set_favorite/`, body);
}
