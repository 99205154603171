import {
  fetchComputeAsset,
  fetchComputeAssets,
} from 'computeAssets/services/ComputeAssetService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchComputeAssetCompleted,
  fetchComputeAssetFailed,
  fetchComputeAssetRequested,
  fetchComputeAssetsCompleted,
  fetchComputeAssetsFailed,
  fetchComputeAssetsRequested,
} from './slice';

function* fetchComputeAssetsEffectSaga(action) {
  try {
    let { data } = yield call(fetchComputeAssets, action.payload);
    yield put(fetchComputeAssetsCompleted(data));
  } catch (e) {
    yield put(fetchComputeAssetsFailed());
  }
}

function* fetchComputeAssetsWatcherSaga() {
  yield takeLatest(
    fetchComputeAssetsRequested.toString(),
    fetchComputeAssetsEffectSaga,
  );
}

function* fetchComputeAssetEffectSaga(action) {
  try {
    let { data } = yield call(fetchComputeAsset, action.payload);
    yield put(fetchComputeAssetCompleted(data));
  } catch (e) {
    yield put(fetchComputeAssetFailed());
  }
}

function* fetchComputeAssetWatcherSaga() {
  yield takeLatest(
    fetchComputeAssetRequested.toString(),
    fetchComputeAssetEffectSaga,
  );
}

export default function* computeAssetsRootSaga() {
  yield all([fetchComputeAssetsWatcherSaga(), fetchComputeAssetWatcherSaga()]);
}
